import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AttachLogo from '../../Assets/Images/svg/attachmentIcon.svg';
import CancelUpload from '../../Assets/Images/svg/cancelUpload.svg';
import CntxtLogo from '../../Assets/Images/svg/cntxtLogo.svg';
import CloudLogo from '../../Assets/Images/svg/GoogleCloudBrandmark.svg';
import tickIcon from '../../Assets/Images/svg/newTickIcon.svg';
import profileIcon from '../../Assets/Images/svg/profileIcon.svg';
import SupportIcon from '../../Assets/Images/svg/supportIconBlue.svg';
import { AppDispatch, RootState } from '../../Store';
import { sendSupportEmailAsync } from '../../Utils/api';
import { ButtonColor } from '../../Utils/constants';
import { validateEmail } from '../../Utils/helperFunctions';
import CustomButton from './CustomButton';
import Dropdown from './Dropdown';
import LogoutSection from './LogoutSection';
import Modal from './Modal';
import Spinner from './Spinner';

type LogoType = {
  hideText?: boolean;
};

const LogoSection = ({ hideText = false }: LogoType) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const email = useSelector((state: RootState) => state.signup.customer?.customer?.user?.email);
  const fullName = useSelector((state: RootState) => state.signup.customer?.customer?.name);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [formData, setFormData] = useState({
    subject: '',
    email: '',
    message: '',
    name: fullName ?? '',
    file: null as File | null,
  });

  useEffect(() => {
    const allRequiredFieldsFilled =
      formData.subject !== '' &&
      formData.email !== '' &&
      formData.message !== '' &&
      validateEmail(formData.email);
    setIsSubmitDisabled(!allRequiredFieldsFilled);
  }, [formData]);

  const openDialog = () => {
    setIsModalOpen(true);
  };

  const closeSuccessDialog = () => {
    setIsSuccessModalOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (name === 'email') {
      if (!validateEmail(value)) {
        setEmailErrorMessage('Please enter a valid email address.');
      } else {
        setEmailErrorMessage('');
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  console.log(errorMessage);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setFormData((prevData) => ({
      ...prevData,
      file: file,
    }));
  };

  const handleSubmit = () => {
    const prefix = 'COBS-ISSUE from ';
    let updatedSubject = formData.subject;

    if (!updatedSubject.startsWith(prefix)) {
      updatedSubject = `${prefix}${updatedSubject}`;
    }

    const updatedFormData = {
      ...formData,
      subject: updatedSubject,
    };

    dispatch(
      sendSupportEmailAsync(
        updatedFormData,
        setIsLoading,
        setIsModalOpen,
        setIsSuccessModalOpen,
        setErrorMessage,
        navigate,
      ),
    );
    setFormData({ subject: '', email: '', message: '', name: fullName, file: null as File | null });
  };

  const handleReset = () => {
    setFormData((prevData) => ({
      ...prevData,
      file: null,
    }));
  };

  return (
    <>
      <div className="flex justify-between">
        <img className="w-[100px] md:w-[150px] h-auto" src={CntxtLogo} alt="cntxt logo" />
        <div className="flex gap-1 md:gap-5 justify-center items-center flex-row md:mb-0">
          <button className="" type="button" onClick={openDialog}>
            <div className="flex gap-2 cursor-pointer">
              <img className="h-6" src={SupportIcon} alt="profile icon" />
              {!hideText && <p className="hidden md:block poppins-400 text-blue-1">Contact Us</p>}
            </div>
          </button>

          <div className="h-[80%] w-[1px] bg-gray-2"></div>
          <img className="w-[100px] md:w-[150px] h-auto" src={CloudLogo} alt="google cloud logo" />
          <div className="h-[80%] w-[1px] bg-gray-2"></div>
          <Dropdown options={<LogoutSection />}>
            <div className="flex items-center gap-1">
              <img className="min-w-[24px] min-h-[24px]" src={profileIcon} alt="profile icon" />
              <p className="hidden md:block text-xs md:text-sm poppins-500 text-gray-1 opacity-70">
                {email}
              </p>
            </div>
          </Dropdown>
        </div>
      </div>
      <div className="hidden md:block border-t border-gray-3 my-2"></div>

      {isModalOpen && (
        <Modal title="Contact Us" setIsModalOpen={setIsModalOpen}>
          {isLoading ? (
            <div className="h-[400px]">
              <Spinner adjustContainerHeight />
            </div>
          ) : (
            <>
              <p className="text-[18.3px] text-gray-7 max-w-[80%] md:max-w-[786px] poppins-400">
                Any question or need help? Just write us a message!
              </p>
              <div className="flex flex-col md:flex-row w-full gap-6 mt-12 mb-6">
                <div className="w-full md:w-[50%]">
                  <p>Full Name</p>
                  <input
                    className={`py-4 poppins-500 border border-gray-2 p-2 w-full rounded-[10px]`}
                    type="text"
                    placeholder="Example"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    maxLength={40}
                  />
                </div>
                <div className="w-full md:w-[50%]">
                  <p>Email Address</p>
                  <input
                    className={`py-4 poppins-500 border border-gray-2 p-2 w-full rounded-[10px]`}
                    type="text"
                    placeholder="example@example.com"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    maxLength={40}
                  />
                  {emailErrorMessage && (
                    <p className="poppins-400 text-[14px] text-center text-red-0 mb-4">
                      {emailErrorMessage}
                    </p>
                  )}
                </div>
              </div>
              <div className="w-full mb-6">
                <p>Description</p>
                <textarea
                  className="py-4 poppins-500 border border-gray-2 p-2 w-full rounded-[10px] resize-none"
                  placeholder="Tell us more"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  maxLength={200}
                  rows={3}
                />
              </div>
              <div className="w-full mb-6">
                <p>Attachment (Optional)</p>
                {formData.file ? (
                  <div className="flex items-center gap-x-3">
                    <p className="text-left text-sm leading-loose text-gray-1 opacity-70">
                      {formData.file?.name}
                    </p>
                    <button
                      type="button"
                      onClick={handleReset}
                      className="cursor-pointer p-1"
                      aria-label="Cancel upload"
                      style={{ background: 'none', border: 'none', padding: 0 }}
                    >
                      <img src={CancelUpload} className="w-5 h-auto" alt="cancel upload icon" />
                    </button>
                  </div>
                ) : (
                  <div className="relative border-dashed border-2 border-gray-2 flex flex-col justify-center items-center cursor-pointer">
                    <input
                      type="file"
                      className="absolute w-full h-full top-0 bottom-0 opacity-0 cursor-pointer z-10 border border-gray-2"
                      accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png, image/jpeg, image/jpg"
                      onChange={handleFileChange}
                    />
                    <div className="flex gap-1 py-4">
                      <img className="w-[18px] h-[18px]" src={AttachLogo} alt="file upload icon" />
                      <p className="text-sm font-normal poppins-300 text-gray-1 opacity-50">
                        Add file here
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {errorMessage && (
                <p className="poppins-400 text-[14px] text-center text-red-0 mb-4">
                  {errorMessage}
                </p>
              )}
              <div className="flex justify-center">
                <CustomButton
                  type="button"
                  className="min-w-[80%] md:min-w-[200px] font-bold"
                  colorClass={ButtonColor.DARK_BLUE}
                  onClick={handleSubmit}
                  disabled={isSubmitDisabled}
                >
                  Submit
                </CustomButton>
              </div>
            </>
          )}
        </Modal>
      )}

      {isSuccessModalOpen && (
        <Modal title="" setIsModalOpen={setIsSuccessModalOpen}>
          <div className="py-16">
            <div className="w-full flex justify-center">
              <img className="" src={tickIcon} alt="success icon" />
            </div>
            <p className="text-[28px] text-center text-black-0 poppins-700">Thank you</p>
            <p className="text-[18.3px] mx-auto text-center text-gray-7 max-w-[80%] md:max-w-[60%] poppins-400 mb-6">
              Your message has been received and we will get back to you shortly !
            </p>

            <div className="flex justify-center">
              <CustomButton
                className="min-w-[80%] md:min-w-[300px]  font-bold"
                colorClass={ButtonColor.DARK_BLUE}
                onClick={closeSuccessDialog}
              >
                Back to the onboarding
              </CustomButton>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
export default LogoSection;
