import { ReactNode } from 'react';

type DashboardCardProps = {
  image: string;
  title: string;
  value?: string;
  valueStyling?: string;
  subTitle?: string;
  children?: ReactNode;
};

const DashboardCard = ({
  image,
  title,
  value,
  valueStyling,
  subTitle,
  children,
}: DashboardCardProps) => {
  return (
    <div className="bg-white-0 border rounded-[10px] border-gray-2  w-full max-h-[200px] flex flex-col items-start px-[23px] py-[21px]">
      <div className="flex flex-row w-full">
        <img className="w-[57px] h-[57px]" src={image} alt={title} />
        <div className=" flex w-full justify-end items-start">{children}</div>
      </div>

      {subTitle ? (
        <div className="flex flex-col items-start">
          <h1 className="poppins-500 text-3xl text-gray-1 mt-[15px] underline decoration-solid text-left">
            {title}
          </h1>
          <h2 className="poppins-400 text-2xl text-gray-1 underline decoration-solid mt-0">
            {subTitle}
          </h2>
        </div>
      ) : (
        <div className="flex flex-col w-full items-start">
          <h2 className="text-base poppins-500 text-gray-1 opacity-[70%] mt-[15px]">{title}</h2>
          <h1 className={`poppins-700 text-3xl text-gray-1 mt-[4px] ${valueStyling}`}>{value}</h1>
        </div>
      )}
    </div>
  );
};
export default DashboardCard;
