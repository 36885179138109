import CustomButton from '../../../Components/Common/CustomButton';
import Modal from '../../../Components/Common/Modal';
import Spinner from '../../../Components/Common/Spinner';
import { ButtonColor } from '../../../Utils/constants';

type ModalTypes = {
  setIsBillingModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  billingGroup: string;
  handleEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage: string;
  handleBillingGroup: () => void;
};

const BillingEmailModal = ({
  setIsBillingModalOpen,
  isLoading,
  billingGroup,
  handleEmailChange,
  errorMessage,
  handleBillingGroup,
}: ModalTypes) => {
  return (
    <Modal title="Billing Permissions" setIsModalOpen={setIsBillingModalOpen}>
      <div className="flex flex-col gap-3">
        {isLoading && <Spinner adjustContainerHeight />}
        {!isLoading && (
          <div>
            <h3 className="text-sm text-left poppins-500 leading-loose text-gray-1">
              Billing Permission Email/Group
            </h3>
            <input
              className="border border-gray-2 p-2 w-full poppins-400 py-4"
              type="text"
              placeholder="Billing Permission Email/Group"
              value={billingGroup}
              onChange={handleEmailChange}
            />
          </div>
        )}

        <div className="flex justify-center items-center">
          {errorMessage && <p className="text-[14px] text-red-0 max-w-[300px]">{errorMessage}</p>}
          <CustomButton
            className="ml-auto min-w-[120px]"
            onClick={handleBillingGroup}
            colorClass={ButtonColor.DARK_BLUE}
          >
            Save
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
};

export default BillingEmailModal;
