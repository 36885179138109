import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CancelUpload from '../../../Assets/Images/svg/cancelUpload.svg';
import FileUpload from '../../../Assets/Images/svg/fileUpload.svg';
import LogoSection from '../../../Components/Common/LogoSection';
import Spinner from '../../../Components/Common/Spinner';
import Stepper from '../../../Components/Common/Stepper';
import StepperMobile from '../../../Components/Common/StepperMobile';
import { updateOnboardingStep } from '../../../Reducers/signupReducer';
import { AppDispatch, RootState } from '../../../Store';
import { addFileAttachmentsAsync } from '../../../Utils/api';
import { BUSINESS, MAX_FILE_SIZE, ONBOARDING_STEPS } from '../../../Utils/constants';
import ButtonsSection from '../Common/ButtonsSection';

const UploadDocuments = () => {
  const storedFiles = useSelector((state: RootState) => state.signup.customer?.customer?.files);
  const vatId = useSelector((state: RootState) => state.signup.customer?.customer?.vatId);
  const navigate = useNavigate();

  const [commercialRegistration, setCommercialRegistration] = useState<any | null>(null);
  const [vatCertificate, setVatCertificate] = useState<any | null>(null);
  const [proofOfAddress, setProofOfAddress] = useState<any | null>(null);
  const [zatcaCertificate, setZatcaCertificate] = useState<any | null>(null);
  const [commercialRegistrationUpdated, setCommercialRegistrationUpdated] = useState(false);
  const [vatCertificateUpdated, setVatCertificateUpdated] = useState(false);
  const [proofOfAddressUpdated, setProofOfAddressUpdated] = useState(false);
  const [zatcaCertificateUpdated, setZatcaCertificateUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (storedFiles && storedFiles.length > 0) {
      setCommercialRegistration(
        storedFiles.find((file) => file.documentType === 'registration') || null,
      );
      setVatCertificate(storedFiles.find((file) => file.documentType === 'vat') || null);
      setProofOfAddress(storedFiles.find((file) => file.documentType === 'address') || null);
      setZatcaCertificate(storedFiles.find((file) => file.documentType === 'zatca') || null);
    }
  }, [storedFiles]);

  useEffect(() => {
    if (commercialRegistration && proofOfAddress && zatcaCertificate) {
      if (vatId && vatCertificate) {
        setShowError(false);
      }
    }
  }, [commercialRegistration, vatCertificate, proofOfAddress, zatcaCertificate, vatId]);

  const dispatch = useDispatch<AppDispatch>();

  const handleNextStep = async () => {
    setShowError(false);
    // Check if any document has been updated
    if (commercialRegistration && proofOfAddress && zatcaCertificate) {
      if (vatId && !vatCertificate) {
        setShowError(true);
        return;
      }
      const formData = new FormData();

      // Append only updated documents
      if (commercialRegistrationUpdated && commercialRegistration) {
        formData.append('registration', commercialRegistration);
      }
      if (vatCertificateUpdated && vatCertificate) {
        formData.append('vat', vatCertificate);
      }
      if (proofOfAddressUpdated && proofOfAddress) {
        formData.append('address', proofOfAddress);
      }
      if (zatcaCertificateUpdated && zatcaCertificate) {
        formData.append('zatca', zatcaCertificate);
      }

      dispatch(addFileAttachmentsAsync(formData, setIsLoading, setErrorMessage, navigate));
    } else {
      setShowError(true);
    }
  };

  const handleBackStep = () => {
    dispatch(updateOnboardingStep(ONBOARDING_STEPS.SUPPORT_PACKAGE));
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setState: React.Dispatch<React.SetStateAction<File | null>>,
    setUpdated: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileSizeKiloBytes = file.size / 1024;
      if (fileSizeKiloBytes <= MAX_FILE_SIZE) {
        setState(file);
        setUpdated(true);
        setErrorMessage('');
      } else {
        setErrorMessage('Please upload file less than 10 MB');
      }
    }
  };

  const handleReset = (setState: React.Dispatch<React.SetStateAction<File | null>>) => {
    setState(null);
  };

  return (
    <div className="w-full h-full flex items-stretch lg:items-center justify-center">
      {isLoading && <Spinner />}
      {!isLoading && (
        <div className="border-[1px] border-gray-3 rounded-lg py-4 px-8 bg-white-0 min-h-[680px] w-[98%] overflow-auto flex flex-col justify-between">
          <LogoSection />
          <Stepper type={BUSINESS} currentStep={3} />
          <StepperMobile
            stepNumber={3}
            stepName="Upload Documents"
            nextStep="Billing Email"
            totalSteps={5}
          />
          <div className="flex gap-2 items-center my-5">
            <h1 className="text-black-0 text-lg font-bold poppins-700 whitespace-nowrap">
              Upload Documents
            </h1>
            <div className="w-full h-[1px] bg-gray-17"></div>
          </div>
          <div className="flex flex-col md:flex-row w-full md:gap-12 gap-6">
            <div className="w-[90%]">
              <h3 className="text-left justify-between text-base poppins-500 text-gray-16">
                Commercial Registration
              </h3>
              <div>
                {commercialRegistration ? (
                  <div className="flex items-center gap-x-3">
                    <p className="text-left text-sm leading-loose text-gray-1 opacity-70">
                      {commercialRegistration.name || commercialRegistration.fileName}
                    </p>
                    <button
                      onClick={() => handleReset(setCommercialRegistration)}
                      className="cursor-pointer p-1"
                      aria-label="Cancel upload"
                      style={{ background: 'none', border: 'none', padding: 0 }}
                    >
                      <img src={CancelUpload} className="w-5 h-auto" alt="cancel upload icon" />
                    </button>
                  </div>
                ) : (
                  <div className="relative border-dashed border-2 border-gray-2 flex flex-col justify-center items-center py-8">
                    <input
                      type="file"
                      className="absolute w-full h-full top-0 bottom-0 opacity-0 cursor-pointer z-10"
                      onChange={(event) =>
                        handleFileChange(
                          event,
                          setCommercialRegistration,
                          setCommercialRegistrationUpdated,
                        )
                      }
                      accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png, image/jpeg, image/jpg"
                    />

                    <img className="w-[50px] h-auto" src={FileUpload} alt="file upload icon" />
                    <p className="text-sm font-normal poppins-300 text-gray-1 opacity-50">Upload</p>
                  </div>
                )}
              </div>
            </div>
            <div className="w-[90%]">
              <h3 className="text-left text-base poppins-500 text-gray-16">
                ZATCA Registration Certificate
              </h3>
              <div>
                {zatcaCertificate ? (
                  <div className="flex items-center gap-x-3">
                    <p className="text-left text-sm leading-loose text-gray-1 opacity-70">
                      {zatcaCertificate.name || zatcaCertificate.fileName}
                    </p>
                    <button
                      onClick={() => handleReset(setZatcaCertificate)}
                      className="cursor-pointer p-1"
                      aria-label="Cancel upload"
                      style={{ background: 'none', border: 'none', padding: 0 }}
                    >
                      <img src={CancelUpload} className="w-5 h-auto" alt="cancel upload icon" />
                    </button>
                  </div>
                ) : (
                  <div className="relative border-dashed border-2 border-gray-2 flex flex-col justify-center items-center py-8">
                    <input
                      type="file"
                      className="absolute w-full h-full top-0 bottom-0 opacity-0 cursor-pointer z-10"
                      onChange={(event) =>
                        handleFileChange(event, setZatcaCertificate, setZatcaCertificateUpdated)
                      }
                      accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png, image/jpeg, image/jpg"
                    />

                    <img className="w-[50px] h-auto" src={FileUpload} alt="file upload icon" />
                    <p className="text-sm font-normal text-gray-1 opacity-50 ">Upload</p>
                  </div>
                )}
              </div>
            </div>
            {vatId && (
              <div className="w-[90%]">
                <h3 className="text-left text-base poppins-500 text-gray-16">VAT Certificate</h3>
                <div>
                  {vatCertificate ? (
                    <div className="flex items-center gap-x-3">
                      <p className="text-left text-sm leading-loose text-gray-1 opacity-70">
                        {vatCertificate.name || vatCertificate.fileName}
                      </p>
                      <button
                        onClick={() => handleReset(setVatCertificate)}
                        className="cursor-pointer p-1"
                        aria-label="Cancel upload"
                        style={{ background: 'none', border: 'none', padding: 0 }}
                      >
                        <img src={CancelUpload} className="w-5 h-auto" alt="cancel upload icon" />
                      </button>
                    </div>
                  ) : (
                    <div className="relative border-dashed border-2 border-gray-2 flex flex-col justify-center items-center py-8">
                      <input
                        type="file"
                        className="absolute w-full h-full top-0 bottom-0 opacity-0 cursor-pointer z-10"
                        onChange={(event) =>
                          handleFileChange(event, setVatCertificate, setVatCertificateUpdated)
                        }
                        accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png, image/jpeg, image/jpg"
                      />

                      <img className="w-[50px] h-auto" src={FileUpload} alt="file upload icon" />
                      <p className="text-sm font-normal text-gray-1 opacity-50">Upload</p>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="w-[90%]">
              <h3 className="text-left text-base poppins-500 text-gray-16">Proof Of Address</h3>
              <div>
                {proofOfAddress ? (
                  <div className="flex items-center gap-x-3">
                    <p className="text-left text-sm leading-loose text-gray-1 opacity-70">
                      {proofOfAddress.name || proofOfAddress.fileName}
                    </p>
                    <button
                      onClick={() => handleReset(setProofOfAddress)}
                      className="cursor-pointer p-1"
                      aria-label="Cancel upload"
                      style={{ background: 'none', border: 'none', padding: 0 }}
                    >
                      <img src={CancelUpload} className="w-5 h-auto" alt="cancel upload icon" />
                    </button>
                  </div>
                ) : (
                  <div className="relative border-dashed border-2 border-gray-2 flex flex-col justify-center items-center py-8">
                    <input
                      type="file"
                      className="absolute w-full h-full top-0 bottom-0 opacity-0 cursor-pointer z-10"
                      onChange={(event) =>
                        handleFileChange(event, setProofOfAddress, setProofOfAddressUpdated)
                      }
                      accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png, image/jpeg, image/jpg"
                    />

                    <img className="w-[50px] h-auto" src={FileUpload} alt="file upload icon" />
                    <p className="text-sm font-normal text-gray-1 opacity-50">Upload</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          {showError && (
            <p className="text-[10p] text-center poppins-300 py-2 text-red-0 max-w-[300px]">
              Please attach all files to proceed.
            </p>
          )}
          <div className="mt-auto">
            <ButtonsSection
              errorMessage={errorMessage}
              isBackButton={true}
              backFunction={handleBackStep}
              isNextButton={true}
              nextFunction={handleNextStep}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadDocuments;
