import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerObject, defaultCustomer } from '../Types';

export interface AdminState {
  adminUser: CustomerObject;
  currentCustomer?: number;
}

const initialState: AdminState = {
  adminUser: { customer: defaultCustomer },
  currentCustomer: undefined,
};

export const signupSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    fetchAdminDetails: (state, action: PayloadAction<CustomerObject>) => {
      state.adminUser = action.payload;
    },
    updateCurrentCustomer: (state, action: PayloadAction<number>) => {
      state.currentCustomer = action.payload;
    },
    resetAdmin: (state) => {
      state.adminUser = { customer: defaultCustomer };
      state.currentCustomer = undefined;
    },
  },
});

export const { resetAdmin, fetchAdminDetails, updateCurrentCustomer } = signupSlice.actions;

export default signupSlice.reducer;
