import ErrorLogo from '../../../Assets/Images/svg/error.svg';
import CustomButton from '../../../Components/Common/CustomButton';
import Modal from '../../../Components/Common/Modal';
import { ButtonColor } from '../../../Utils/constants';

type ModalTypes = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleYes: () => void;
};

const BillingPermissionModal = ({ setIsModalOpen, handleYes }: ModalTypes) => {
  return (
    <Modal title="" setIsModalOpen={setIsModalOpen}>
      <div className="w-full flex items-center justify-center">
        <img className="mt-2" src={ErrorLogo} alt="Error logo" />
      </div>
      <h3 className="poppins-700 text-2xl text-center mt-4">Billing Permission Confirmation</h3>
      <p className="poppins-400 text-gray-7 opacity-70 max-w-[600px] my-4 text-center w-[70%] mx-auto">
        Have you reviewed customers details and confirm granting billing admin permissions.
      </p>
      <div className="flex justify-center gap-x-5 mt-8 mb-3">
        <CustomButton
          className="px-8 py-3 font-bold min-w-[45%]"
          colorClass={ButtonColor.White}
          onClick={() => setIsModalOpen(false)}
        >
          No, let me review details
        </CustomButton>
        <CustomButton
          className="px-8 py-3 font-bold min-w-[45%]"
          colorClass={ButtonColor.RED_2}
          onClick={handleYes}
        >
          Yes, assign permissions
        </CustomButton>
      </div>
    </Modal>
  );
};

export default BillingPermissionModal;
