import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TermBackground from '../../Assets/Images/svg/termsBackground.svg';
import LogoSection from '../../Components/Common/LogoSection';
import Spinner from '../../Components/Common/Spinner';
import { updateOnboardingStep } from '../../Reducers/signupReducer';
import { AppDispatch, RootState } from '../../Store';
import { acceptTermsAndConditionsAsync } from '../../Utils/api';
import { ONBOARDING_STEPS } from '../../Utils/constants';
import ButtonsSection from './Common/ButtonsSection';

const Terms = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { URL_PRIVACY_POLICY, URL_SUBSCRIPTION_AGREEMENT, URL_TERMS_OF_USE } = useSelector(
    (state: RootState) => state.urls,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleCreateAccount = () => {
    dispatch(acceptTermsAndConditionsAsync(setIsLoading, setErrorMessage, navigate));
  };

  const handleBack = () => {
    dispatch(updateOnboardingStep(ONBOARDING_STEPS.PAYMENT_INFORMATION));
  };
  return (
    <div className="w-full h-full flex items-stretch lg:items-start justify-center">
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="border-[1px] border-gray-3 rounded-lg py-4 px-8 bg-white-0 min-h-[680px] w-[98%] overflow-auto flex flex-col my-auto xxl:mt-8">
          <LogoSection />
          <div className="flex gap-2 my-5 justify-between">
            <div className="flex flex-col gap-y-7 w-full md:w-1/2">
              <h1 className="text-2xl text-left text-gray-1 poppins-700 whitespace-nowrap">
                Almost done, last thing
              </h1>
              <div className="flex flex-col gap-y-3 md:w-2/3">
                <h3 className="text-lg text-left poppins-500 text-gray-1">
                  Terms & Conditions Agreement
                </h3>
                <p className="text-sm text-left text-gray-1/70 poppins-400">
                  By pressing Create Account, I hereby agree to{' '}
                  <a
                    className="no-underline text-blue-1 hover:opacity-80 font-semibold"
                    href={URL_PRIVACY_POLICY}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy
                  </a>
                  ,{' '}
                  <a
                    className="no-underline text-blue-1 hover:opacity-80 font-semibold"
                    href={URL_TERMS_OF_USE}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Use
                  </a>{' '}
                  and{' '}
                  <a
                    className="no-underline text-blue-1 hover:opacity-80 font-semibold"
                    href={URL_SUBSCRIPTION_AGREEMENT}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Subscription Agreement
                  </a>
                  , under which I am contracting with CNTXT
                </p>
              </div>
            </div>
            <div className="hidden md:flex flex-col gap-y-7 w-full md:w-1/2 h-[60vh]">
              <img className="h-full" src={TermBackground} alt="terms background" />
            </div>
          </div>
          <div className="mt-auto">
            <ButtonsSection
              errorMessage={errorMessage}
              isBackButton={true}
              backFunction={handleBack}
              isNextButton={false}
              isCreateAccountButton={true}
              createAccountFunction={handleCreateAccount}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Terms;
