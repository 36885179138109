import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../Store';
import { Navigation, Role, TrackStep } from '../Utils/constants';

const NotFound = () => {
  const step = useSelector((state: RootState) => state.signup?.customer?.customer?.step);
  const adminRole = useSelector((state: RootState) => state.admin?.adminUser?.customer?.user?.role);
  const role = useSelector((state: RootState) => state.signup?.customer?.customer?.user?.role);
  if (adminRole === Role.ADMIN) {
    return <Navigate to={Navigation.MANAGE_CUSTOMERS} />;
  } else if (adminRole === '' && role === Role.USER) {
    return <>{step === TrackStep.COMPLETED ? <Navigate to="/dashboard" /> : <Navigate to="/" />}</>;
  }
  return <Navigate to="/" />;
};
export default NotFound;
