import CustomButton from '../../../Components/Common/CustomButton';
import Modal from '../../../Components/Common/Modal';
import { ButtonColor } from '../../../Utils/constants';

type ModalTypes = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MigrateSuccessModal = ({ setIsModalOpen }: ModalTypes) => {
  const closeDialog = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal title="Migration request submitted" setIsModalOpen={setIsModalOpen}>
      <p className="text-[18.3px] text-gray-1 opacity-70 max-w-[786px] my-6 py-12">
        Your request to migrate projects from the existing Google Cloud account has been submitted.
        You will receive a confirmation email once billing migration completes (usually 7-14 days).
      </p>

      <div className="flex justify-end">
        <CustomButton
          className="px-8 font-bold"
          colorClass={ButtonColor.DARK_BLUE}
          onClick={closeDialog}
        >
          Close
        </CustomButton>
      </div>
    </Modal>
  );
};

export default MigrateSuccessModal;
