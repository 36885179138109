const PathsAllowed = {
  user: ['/dashboard', '/billingHistory', '/manageUsers', '/managePayment', '/subscription'],
  admin: [
    '/dashboard',
    '/billingHistory',
    '/manageUsers',
    '/managePayment',
    '/subscription',
    '/manageCustomers',
  ],
};

export default PathsAllowed;
