import AddIcon from '../../Assets/Images/svg/addIcon.svg';

type Props = {
  title: string;
  showAddCard: () => void;
  description?: string;
};

const AddCardButton = ({ title, showAddCard, description }: Props) => {
  return (
    <button
      className="bg-white-0 border border-dashed border-gray-3 rounded-xl p-[25px] w-[330px] flex flex-col justify-center items-center "
      onClick={showAddCard}
    >
      <div className="flex gap-2 w-full justify-center items-center">
        <img src={AddIcon} alt="Add Icon" />
        <h1 className="text-blue-1 poppins-500 text-xl">{title}</h1>
      </div>
      {description && (
        <p className="text-base poppins-400 leading-none text-gray-1 opacity-[70%] mt-2">
          {description}
        </p>
      )}
    </button>
  );
};

export default AddCardButton;
