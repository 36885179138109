import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as CardIcon } from '../../Assets/Images/svg/pageMyCards.svg';
import CreditCardForm from '../../Components/Common/CreditCardForm';
import Modal from '../../Components/Common/Modal';
import NavigationBar from '../../Components/Common/NavigationBar/index';
import Spinner from '../../Components/Common/Spinner';
import TitleCard from '../../Components/Common/TitleCard';
import { addCardType, addVerifyingCard, resetVerifyingCardData } from '../../Reducers/cardReducer';
import { AppDispatch, RootState } from '../../Store';
import { PaymentApiResponse, StatusDetails } from '../../Types';
import {
  fetchPaymentMethodData,
  getCustomerPackageDetailsAsync,
  saveCardDetails,
} from '../../Utils/api';
import {
  Card,
  DashBoardTab,
  Moyassar3dSecureResponse,
  MoyassarErrorMessages,
  Role,
  StatusReason,
  SubscriptionStatus,
} from '../../Utils/constants';
import { isExpired } from '../../Utils/helperFunctions';
import CardOptions from '../Signup/IndividualOnboarding/CardOptions';
import AddCardButton from './AddCardButton';
import CardComponent from './CardComponent';

export const updatePaymentDisabled = (status: string, statusDetails: StatusDetails) => {
  const suspendedReason = statusDetails ? statusDetails.reason : null;

  // Disable payment method update if user "status" is 'active' yet 'pending-review'
  // or 'cancelled' or 'pending-cancellation' but not 'failed-settlement'
  // or 'suspended' due to 'security' or 'pending-review' reasons
  const updateDisabled =
    (status === SubscriptionStatus.ACTIVE && suspendedReason === StatusReason.PENDING_REVIEW) ||
    status === SubscriptionStatus.CANCELLED ||
    (status === SubscriptionStatus.PENDING_CANCELLATION &&
      suspendedReason !== StatusReason.FAILED_SETTLEMENT) ||
    (status === SubscriptionStatus.SUSPENDED &&
      (suspendedReason === StatusReason.SECURITY ||
        suspendedReason === StatusReason.PENDING_REVIEW));
  return updateDisabled;
};

const ManagePayment = () => {
  const [isPrimaryCard, setIsPrimaryCard] = useState(false);
  const [isBackupCard, setIsBackupCard] = useState(false);
  const [isOtherCard, setIsOtherCard] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [cardError, setCardError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [cardApproved, setCardApproved] = useState(false);
  const [verificationUrl, setVerificationUrl] = useState('');
  const [cardData, setCardData] = useState({
    name: '',
    number: '',
    cvc: '',
    month: '',
    year: '',
  });
  const [currentUrl, setCurrentUrl] = useState('');

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const role = useSelector((state: RootState) => state.admin?.adminUser?.customer?.user?.role);
  const userCards = useSelector((state: RootState) => state.cards?.cards);
  const verifyingCard = useSelector((state: RootState) => state.cards?.verifyingCard);
  const cardType = useSelector((state: RootState) => state.cards?.cardType);
  const customerId = useSelector((state: RootState) => state.admin?.currentCustomer);
  const { status, statusDetails } = useSelector((state: RootState) => state.subscription);
  const updateDisabled = updatePaymentDisabled(status, statusDetails);
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const messages = params.getAll('message');
  const message = messages.length > 0 ? messages[messages.length - 1] : null;
  const ids = params.getAll('id');
  const paymentId = ids.length > 0 ? ids[ids.length - 1] : '';

  useEffect(() => {
    const handleResponse = () => {
      if (message && message in MoyassarErrorMessages) {
        setCardError(MoyassarErrorMessages[message]);
        setShowModal(true);
        if (message === Moyassar3dSecureResponse.DECLINED_INVALID_PIN) {
          const savedCardData = localStorage.getItem('cardData');
          if (savedCardData) {
            setCardData(JSON.parse(savedCardData));
          }
        }
        dispatch(resetVerifyingCardData());
        localStorage.removeItem('cardData');
      } else if (message === Moyassar3dSecureResponse.APPROVED) {
        setCardApproved(true);
        setShowModal(false);
        localStorage.removeItem('cardData');
      }
      setCurrentUrl(window.location.href);
    };
    handleResponse();
  }, [message, dispatch, setCurrentUrl]);

  useEffect(() => {
    if (verificationUrl) {
      //direct to 3d secure url
      setShowModal(false);
      window.open(verificationUrl, '_self');
    }
  }, [verificationUrl]);

  useEffect(() => {
    if (verifyingCard) {
      const { status, expiryDate, paymentIdentifier, last4, type, cardHolderName } = verifyingCard;
      if (cardApproved && paymentIdentifier.length > 0) {
        dispatch(resetVerifyingCardData());
        const isAdmin = role === Role.ADMIN;
        dispatch(
          saveCardDetails({
            paymentId: paymentId,
            id: paymentIdentifier,
            last4: last4,
            expiryDate: expiryDate,
            status: status,
            type: type,
            navigate: navigate,
            setIsLoading: setIsLoading,
            setErrorMessage: setCardError,
            isAdmin: isAdmin,
            customerId: isAdmin ? customerId : undefined,
            isOnboarding: false,
            setIsModalOpen: setShowModal,
            cardHolderName,
          }),
        );
      }
    }
  }, [cardApproved]);

  useEffect(() => {
    if (role === Role.ADMIN) {
      dispatch(getCustomerPackageDetailsAsync(setIsLoading, setError, navigate, true, customerId));
      dispatch(fetchPaymentMethodData(setIsLoading, setError, navigate, true, customerId));
    } else {
      dispatch(getCustomerPackageDetailsAsync(setIsLoading, setError, navigate, false));
      dispatch(fetchPaymentMethodData(setIsLoading, setError, navigate, false));
    }
  }, []);

  useEffect(() => {
    const isPrimary = userCards.find((card) => card.status === Card.PRIMARY);
    if (isPrimary) setIsPrimaryCard(true);
    else setIsPrimaryCard(false);
    const isBackup = userCards.find((card) => card.status === Card.BACKUP);
    if (isBackup) setIsBackupCard(true);
    else setIsBackupCard(false);
    const isOther = userCards.find((card) => card.status === Card.OTHER);
    if (isOther) setIsOtherCard(true);
    else setIsOtherCard(false);
  }, [userCards]);

  const showAddPrimaryCard = () => {
    dispatch(addCardType(Card.PRIMARY));
    setCardError('');
    setShowModal(true);
  };

  const showAddBackupCard = () => {
    dispatch(addCardType(Card.BACKUP));
    setCardError('');
    setShowModal(true);
  };

  const showAddOtherCard = () => {
    dispatch(addCardType(Card.OTHER));
    setCardError('');
    setShowModal(true);
  };

  const onSaveClick = async () => {
    const apiUrl = 'https://api.moyasar.com/v1/tokens';
    const config = {
      auth: {
        username: process.env.REACT_APP_MOYASAR_PUBLIC_KEY ?? '',
        password: '',
      },
      body: {
        ...cardData,
        callback_url: currentUrl,
      },
    };
    setIsLoading(true);
    axios
      .post(apiUrl, config.body, { auth: config.auth })
      .then((result: AxiosResponse<PaymentApiResponse>) => {
        const expiryDate = `${result?.data?.month}/${result?.data?.year.slice(-2)}`;
        const cardCredentials = {
          id: result.data?.id,
          status: cardType,
          paymentIdentifier: result.data?.id,
          last4: result.data?.last_four,
          expiryDate,
          type: result.data?.brand || 'visa',
          cardHolderName: result.data?.name,
        };
        localStorage.setItem('cardData', JSON.stringify(cardData));
        dispatch(addVerifyingCard(cardCredentials));

        setVerificationUrl(result.data.verification_url);
      })
      .catch((error) => {
        setIsLoading(false);
        let errorMessage = error?.response?.data?.message || 'Invalid card details';
        errorMessage = errorMessage.includes(':')
          ? errorMessage.split(':')[1].trim()
          : errorMessage;
        setCardError(errorMessage);
      });
  };

  const displayCard = (status: string) => {
    const card = userCards.find((card) => card.status === status);

    if (card) {
      const { id, status, type, last4, expiryDate } = card;
      return (
        <CardComponent
          key={id}
          id={id}
          title={status}
          cardDetails={`${type} ****-${last4}`}
          expiryDate={`Expiry ${expiryDate}`}
          dashedBorder={status !== Card.PRIMARY}
          setIsLoading={setIsLoading}
          setError={setError}
          isExpired={isExpired(expiryDate)}
        />
      );
    }
  };

  return (
    <div className="flex flex-row pb-[20px] px-[23px]">
      <div>
        <NavigationBar tab={DashBoardTab.MY_CARDS} />
      </div>
      <div className="flex flex-col ml-[33px] w-full">
        <TitleCard title="Manage Payment" icon={CardIcon} />

        <div className="bg-white-0 rounded-xl border border-gray-3 w-full h-full py-[50px] px-5 relative ">
          {isLoading && <Spinner adjustContainerHeight />}
          {!isLoading && (
            <div>
              <div className="flex flex-row gap-4 mx-3">
                {isPrimaryCard
                  ? displayCard(Card.PRIMARY)
                  : !updateDisabled && (
                      <AddCardButton
                        title="Add a primary card"
                        showAddCard={showAddPrimaryCard}
                        description="The primary card pays the balance automatically"
                      />
                    )}
                {isBackupCard
                  ? displayCard(Card.BACKUP)
                  : !updateDisabled && (
                      <AddCardButton
                        title="Add a backup card"
                        showAddCard={showAddBackupCard}
                        description="When the primary card fails, a backup payment card pays the balance automatically"
                      />
                    )}
                {isOtherCard
                  ? displayCard(Card.OTHER)
                  : !updateDisabled && (
                      <AddCardButton title="Add another card" showAddCard={showAddOtherCard} />
                    )}
              </div>
              <div className="h-px  bg-gray-17 my-[30px] mx-3" />
              <div className="ml-4">
                <CardOptions />
              </div>

              {showModal && (
                <Modal title="Add Card" setIsModalOpen={setShowModal}>
                  <CreditCardForm
                    cardData={cardData}
                    setCardData={setCardData}
                    error={cardError}
                    setError={setCardError}
                    onSaveClick={onSaveClick}
                  />
                </Modal>
              )}
              {error && <p className="text-[14px] text-center text-red-0 ">{error}</p>}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ManagePayment;
