type StepperProps = {
  stepNumber: number;
  totalSteps: number;
  stepName: string;
  nextStep?: string;
};

const StepperMobile = ({ stepNumber, stepName, nextStep, totalSteps }: StepperProps) => {
  return (
    <div className="block md:hidden border border-gray-3 rounded-md py-2 my-8 pl-4">
      <p className="text-gray-16 text-left poppins-700 text-[14px] py-2">
        Step {stepNumber} of {totalSteps}
      </p>
      <div className="flex items-center gap-2 py-2">
        <p className="w-[20px] h-[20px] rounded-[20px] bg-blue-1 flex items-center justify-center text-white-0 text-[12px]">
          {stepNumber}
        </p>
        <p className="text-blue-1 poppins-700 text-[16px] whitespace-nowrap">{stepName}</p>
      </div>
      {nextStep && (
        <p className="text-gray-7 poppins-500 text-[14px] text-left py-2">Next : {nextStep}</p>
      )}
    </div>
  );
};

export default StepperMobile;
