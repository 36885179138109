import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import adminReducer from '../Reducers/adminReducer';
import cardReducer from '../Reducers/cardReducer';
import counterReducer from '../Reducers/counterReducer';
import customersReducer from '../Reducers/customersReducer';
import dashboardReducer from '../Reducers/dashboardReducer';
import invoicesReducer from '../Reducers/invoicesReducer';
import signupReducer from '../Reducers/signupReducer';
import subscriptionReducer from '../Reducers/subscriptionReducer';
import urlsReducer from '../Reducers/urlsReducer';
import usersReducer from '../Reducers/usersReducer';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  counter: counterReducer,
  signup: signupReducer,
  cards: cardReducer,
  subscription: subscriptionReducer,
  dashboard: dashboardReducer,
  invoices: invoicesReducer,
  users: usersReducer,
  customers: customersReducer,
  admin: adminReducer,
  urls: urlsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/REGISTER'],
      },
    }).concat(thunk),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
