import CustomButton from '../../../Components/Common/CustomButton';
import Modal from '../../../Components/Common/Modal';
import { ButtonColor } from '../../../Utils/constants';

type ModalTypes = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleYes: () => void;
  handleNo: () => void;
};

const PackageChangeModal = ({ setIsModalOpen, handleYes, handleNo }: ModalTypes) => {
  return (
    <Modal title="Support Package Upgrade Confirmation" setIsModalOpen={setIsModalOpen}>
      <p className="text-[18.3px] text-gray-1 opacity-70 max-w-[786px] my-6 py-2">
        You would not be able to downgrade support package for 90 days, are you sure to continue.
      </p>

      <div className="flex justify-end gap-x-2">
        <CustomButton
          className="px-8 font-bold"
          colorClass={ButtonColor.DARK_BLUE}
          onClick={handleYes}
        >
          Yes
        </CustomButton>
        <CustomButton className="px-8 font-bold" colorClass={ButtonColor.White} onClick={handleNo}>
          No
        </CustomButton>
      </div>
    </Modal>
  );
};

export default PackageChangeModal;
