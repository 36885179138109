import { useSelector } from 'react-redux';
import { RootState } from '../../Store';
import { ONBOARDING_STEPS } from '../../Utils/constants';
import BillingEmail from './BusinessOnboarding/BillingEmail';
import BusinessInformation from './BusinessOnboarding/BusinessInformation';
import UploadDocuments from './BusinessOnboarding/UploadDocuments';
import ContactInformation from './IndividualOnboarding/ContactInformation';
import PaymentInformation from './IndividualOnboarding/PaymentInformation';
import SupportPackage from './IndividualOnboarding/SupportPackage';
import InitialDetails from './InitialDetails';
import Success from './Success';
import Terms from './Terms';

const SignUp = () => {
  const onboardingStep = useSelector((state: RootState) => state.signup.onboardingStep);

  let component;
  switch (onboardingStep) {
    case ONBOARDING_STEPS.INITIAL_DETAILS:
      component = <InitialDetails />;
      break;
    case ONBOARDING_STEPS.BUSINESS_INFORMATION:
      component = <BusinessInformation />;
      break;
    case ONBOARDING_STEPS.SUPPORT_PACKAGE:
      component = <SupportPackage />;
      break;
    case ONBOARDING_STEPS.UPLOAD_DOCUMENTS:
      component = <UploadDocuments />;
      break;
    case ONBOARDING_STEPS.BILLING_EMAIL:
      component = <BillingEmail />;
      break;
    case ONBOARDING_STEPS.PAYMENT_INFORMATION:
      component = <PaymentInformation />;
      break;
    case ONBOARDING_STEPS.CONTACT_INFORMATION:
      component = <ContactInformation />;
      break;
    case ONBOARDING_STEPS.TERMS:
      component = <Terms />;
      break;
    case ONBOARDING_STEPS.SUCCESS:
      component = <Success />;
      break;
    default:
      component = <InitialDetails />;
  }

  return <>{component}</>;
};
export default SignUp;
