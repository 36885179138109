import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultUser, User, UsersState } from '../Types';

const initialState: UsersState = {
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
  users: [defaultUser] as User[],
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchUsers: (state, action: PayloadAction<UsersState>) => {
      state.totalItems = action.payload.totalItems;
      state.totalPages = action.payload.totalPages;
      state.currentPage = action.payload.currentPage;
      state.users = action.payload.users;
    },
    resetUsers: (state) => {
      state.totalItems = initialState.totalItems;
      state.totalPages = initialState.totalPages;
      state.currentPage = initialState.currentPage;
      state.users = initialState.users;
    },
  },
});

export const { fetchUsers, resetUsers } = usersSlice.actions;

export default usersSlice.reducer;
