import React, { useState } from 'react';

type Props = {
  children: React.ReactNode;
  text: string;
};

const Tooltip = ({ children, text }: Props) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative cursor-pointer">
      <button onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
        {children}
      </button>
      {showTooltip && (
        <button
          className="absolute top-9 right-0 w-40 text-xs text-left text-gray-1 opacity-80 py-3 px-2 rounded-lg shadow-3xl bg-white-0"
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {text}
        </button>
      )}
    </div>
  );
};

export default Tooltip;
