import { GoogleOAuthProvider } from '@react-oauth/google';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import './App.css';
import AppRouter from './Routes/AppRouter';
import { persistor, store } from './Store';

function App() {
  useEffect(() => {
    if (window !== window.top && window.top) {
      window.top.location.href = window.location.href;
    }
  }, []);
  return (
    <div className="App bg-gray-18 flex items-start justify-center h-screen md:h-full">
      <div className="px-0 py-5 max-w-[1440px] max-h-screen xxl:p-4 xxl:max-w-[1550px] md:max-h-[880px] w-full h-full">
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_FIREBASE_CLIENT_ID ?? ''}>
              <BrowserRouter basename="/">
                <AppRouter />
              </BrowserRouter>
            </GoogleOAuthProvider>
          </PersistGate>
        </Provider>
      </div>
    </div>
  );
}

export default App;
