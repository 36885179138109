import CustomButton from '../../../Components/Common/CustomButton';
import Modal from '../../../Components/Common/Modal';

import { ButtonColor } from '../../../Utils/constants';

type ModalTypes = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  closeDialog: () => void;
};

const PaymentSuccessModal = ({ setIsModalOpen, closeDialog }: ModalTypes) => {
  return (
    <Modal title="Payment Successful" setIsModalOpen={setIsModalOpen}>
      <p className="text-[18.3px] text-gray-1 opacity-70 max-w-[786px] my-6 py-2">
        Your payment has been completed successfully.
      </p>

      <div className="flex justify-end">
        <CustomButton
          className="px-8 font-bold"
          colorClass={ButtonColor.DARK_BLUE}
          onClick={closeDialog}
        >
          Close
        </CustomButton>
      </div>
    </Modal>
  );
};

export default PaymentSuccessModal;
