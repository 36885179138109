import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../Components/Common/CustomButton';
import Modal from '../../../Components/Common/Modal';
import Spinner from '../../../Components/Common/Spinner';
import { AppDispatch, RootState } from '../../../Store';
import { migrateUserAsync } from '../../../Utils/api';
import { ButtonColor } from '../../../Utils/constants';

type ModalTypes = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSuccessModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MigrateProjectModal = ({ setIsModalOpen, setIsSuccessModalOpen }: ModalTypes) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [accountId, setAccountId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const gcpSubBillingAccountId = useSelector(
    (state: RootState) => state.signup?.customer?.customer?.gcpSubBillingAccountId,
  );
  const submitMigrationRequest = () => {
    const regex =
      /^([0-9A-Z]{6}-[0-9A-Z]{6}-[0-9A-Z]{6})(,\s?[0-9A-Z]{6}-[0-9A-Z]{6}-[0-9A-Z]{6})*$/;

    if (!regex.test(accountId)) {
      setErrorMessage('Invalid format!');
    } else {
      setErrorMessage('');
      dispatch(
        migrateUserAsync(
          accountId,
          setIsLoading,
          setIsModalOpen,
          setIsSuccessModalOpen,
          setErrorMessage,
          navigate,
        ),
      );
    }
  };
  const handleAccountIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    value = value.toUpperCase().replace(/[^A-Z0-9-]/g, '');
    value = value.replace(/(-{2,})/g, '-').replace(/(,{2,})/g, ',');
    setAccountId(value);
  };

  return (
    <Modal
      title={`${isLoading ? '' : 'Migrate Billing for Existing Google Cloud Projects'}`}
      setIsModalOpen={setIsModalOpen}
    >
      <>
        {isLoading ? (
          <Spinner adjustContainerHeight />
        ) : (
          <>
            <p className="text-[18.3px] text-gray-1 opacity-70 max-w-[786px] my-6">
              You can migrate your existing Google Cloud projects to this CNTXT managed billing
              account. To get your existing billing account ID(s), please visit this{' '}
              <a
                className="underline"
                href="https://console.cloud.google.com/billing"
                target="_blank"
                rel="noreferrer"
              >
                link
              </a>
              .
            </p>
            <p className="text-[18.3px] text-gray-1 opacity-70 max-w-[786px] mt-4 mb-2">
              You have two options to migrate your billing accounts:
            </p>
            <div className="flex gap-2 items-start">
              <h3 className="mt-6">1.</h3>
              <div>
                <p className="text-[18.3px] text-gray-1 opacity-70 max-w-[786px] mt-6">
                  <strong> Self-Migration:</strong> Follow this{' '}
                  <a
                    className="underline"
                    href="https://www.youtube.com/watch?v=maFV-fZA6To&t=65s"
                    target="_blank"
                    rel="noreferrer"
                  >
                    YouTube tutorial
                  </a>{' '}
                  for a step-by-step guide to self-migrate your billing accounts.
                </p>
                <p className="text-[16px] text-gray-1 opacity-70 max-w-[786px] mb-2">
                  If you have fewer than 20 Google projects and no billing credits or committed user
                  discounts, we recommend choosing Self-Migration (Option 1). This approach allows
                  you to complete the migration without waiting for up to 2 weeks.
                </p>
              </div>
            </div>
            <div className="flex gap-2 items-start">
              <h3 className="mt-6">2.</h3>
              <div>
                <p className="text-[18.3px] text-gray-1 opacity-70 max-w-[786px] mt-6">
                  <strong> Google Provided Migration:</strong> Provide your existing billing ID
                  below.
                </p>
                <p className="text-[16px] text-gray-1 opacity-70 max-w-[786px] mb-6">
                  If you have more than 20 projects, billing credits, or committed user discounts,
                  we recommend opting for Google Provided Migration (Option 2) for a more
                  streamlined and managed process.
                </p>
              </div>
            </div>

            <h1 className="font-[700] text-[18.3px] mb-1">CNTXT Provided Billing Account ID</h1>
            <input
              className="flex w-full h-[44px] p-2 items-center rounded-md bg-gray-4 text-gray-5 mb-4"
              type="text"
              placeholder={gcpSubBillingAccountId?.replace('billingAccounts/', '') ?? ''}
              disabled
              maxLength={40}
            />
            <h1 className="font-[700] text-[18.3px] mb-1">Existing Billing Account ID</h1>
            <input
              className="flex w-full h-[44px] p-2 items-center rounded-md border-[1px] border-gray-1 border-opacity-20 text-gray-5 mb-10"
              type="text"
              placeholder="Enter your existing billing account ID."
              maxLength={20}
              value={accountId}
              onChange={handleAccountIdChange}
            />
            {errorMessage && (
              <p className="text-[14px] text-left text-red-0 -mt-6 mb-2">{errorMessage}</p>
            )}
            <div className="flex justify-end">
              <CustomButton
                className="px-8 font-bold"
                colorClass={ButtonColor.DARK_BLUE}
                onClick={submitMigrationRequest}
                disabled={accountId?.length < 20}
              >
                Submit Migration Request
              </CustomButton>
            </div>
          </>
        )}
      </>
    </Modal>
  );
};

export default MigrateProjectModal;
