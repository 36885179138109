import { useEffect, useState } from 'react';
import RightArrow from '../../Assets/Images/svg/rightArrow.svg';
import Tick from '../../Assets/Images/svg/StepperTick.svg';
import { Step } from '../../Types';
import { BUSINESS, BUSINESS_STEPS, INDIVIDUAL_STEPS } from '../../Utils/constants';

type StepperProps = {
  type: 'individual' | 'business';
  currentStep: number;
};

const Stepper = ({ type, currentStep }: StepperProps) => {
  const [data, setData] = useState<Step[]>([]);

  useEffect(() => {
    if (type === BUSINESS) {
      setData(BUSINESS_STEPS);
    } else {
      setData(INDIVIDUAL_STEPS);
    }
  }, [type]);

  return (
    <div className="hidden md:flex items-center border border-gray-3 rounded-md py-2 my-8">
      {data.map((step, index) => {
        const stepText = Object.values(step)[0];
        const isCompleted = index + 1 < currentStep;
        const isCurrent = index + 1 === currentStep;
        return (
          <div
            className={`${index + 1 === currentStep ? 'text-blue-1' : ''} flex items-center px-2`}
            key={stepText}
          >
            {index !== 0 && (
              <img
                className="w-[18px] md:w-[24px] h-[18px] md:h-[24px] md:ml-[9px] md:mr-[18px]"
                src={RightArrow}
                alt="icon"
              />
            )}
            <p
              className={`${
                isCurrent
                  ? 'bg-blue-1 text-white-0 poppins-500'
                  : 'bg-gray-2 text-gray-7 poppins-500'
              } w-[28px] md:w-[34px] h-[28px] md:h-[34px] rounded-full flex items-center justify-center text-center font-titillium-web text-sm md:text-lg leading-loose mx-1 md:mx-[9px]`}
            >
              {isCompleted ? (
                <img className="w-[24px] h-[12px]" src={Tick} alt="completed" />
              ) : (
                index + 1
              )}
            </p>
            <p
              className={`${
                index + 1 === currentStep ? 'text-blue-1 poppins-700' : 'text-gray-15 poppins-500'
              } text-[16px]  hidden md:block`}
            >
              {stepText}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
