import { useEffect, useState } from 'react';
import { ButtonColor } from '../../Utils/constants';
import CustomButton from './CustomButton';

type CardDetails = {
  name: string;
  number: string;
  cvc: string;
  month: string;
  year: string;
};

type CreditCardFormProp = {
  cardData: CardDetails;
  setCardData: (value: CardDetails | ((prevValue: CardDetails) => CardDetails)) => void;
  error?: string;
  setError: (error: string) => void;
  isSaveButton?: boolean;
  onSaveClick?: () => void;
};

const CreditCardForm = ({
  cardData,
  setCardData,
  isSaveButton = true,
  error,
  setError,
  onSaveClick,
}: CreditCardFormProp) => {
  const [expiry, setExpiry] = useState('');

  useEffect(() => {
    if (cardData.month && cardData.year) {
      setExpiry(`${cardData.month}/${cardData.year}`);
    }
  }, [cardData.month, cardData.year]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCardData((prevState: CardDetails) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleExpiryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    let month = '';
    let year = '';

    if (value.length >= 2) {
      month = value.substring(0, 2);
      year = value.length > 2 ? value.substring(2, 4) : '';
    } else {
      month = value;
    }

    const formattedValue = year.length > 0 ? `${month}/${year}` : month;

    setCardData((prevState: CardDetails) => ({
      ...prevState,
      month,
      year,
    }));

    setExpiry(formattedValue);
  };

  const onClick = () => {
    console.log(cardData);
    if (isSaveButton && onSaveClick) {
      const currentDate = new Date();
      const currentMonth: number = currentDate.getMonth() + 1;
      const currentYear: string = new Date().getFullYear().toString().slice(-2);
      if (cardData.number.length !== 16 && cardData.number.length !== 15)
        setError('Invalid card number');
      else if (!cardData.name.includes(' ')) setError('Invalid Card Holder Name');
      else if (cardData.cvc.length !== 3) setError('Invalid CVV');
      else if (Number(cardData.month) > 12) setError('Invalid month');
      else if (Number(cardData.year) < Number(currentYear)) setError('Invalid year');
      else if (
        Number(cardData.year) === Number(currentYear) &&
        Number(cardData.month) < currentMonth
      )
        setError('Card has expired');
      else onSaveClick();
    }
  };

  return (
    <>
      <h1 className="text-gray-1 text-lg mt-4 poppins-500">Card Holder Name</h1>
      <input
        className="border border-gray-2 py-4 p-3 w-full rounded-[10px] mt-1 poppins-500"
        type="text"
        placeholder="Full Name"
        name="name"
        value={cardData.name}
        onChange={handleChange}
        maxLength={120}
        required
      />
      <h1 className="text-gray-1 text-lg mt-4 poppins-500">Card Number</h1>
      <input
        className="border border-gray-2 p-3 py-4 w-full rounded-[10px] mt-1 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none poppins-500"
        type="number"
        placeholder="Card Number"
        name="number"
        maxLength={2}
        onChange={handleChange}
        value={cardData.number}
        required
        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
          e.target.value = e.target.value.slice(0, 16);
        }}
      />
      <div className="flex space-x-12 w-full">
        <div className="flex flex-col w-full">
          <h1 className="text-gray-1 text-lg mt-4 poppins-500">Card Expiry</h1>
          <input
            className="border border-gray-2 p-3 py-4 rounded-[10px] poppins-500 mt-1 w-full text-gray-1 opacity-[70%] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            placeholder="MM/YY"
            maxLength={5}
            type="text"
            value={expiry}
            onChange={handleExpiryChange}
            required
          />
        </div>
        <div className="flex flex-col w-full">
          <h1 className="text-gray-1 text-lg mt-4 poppins-500">CVV</h1>
          <input
            className="border border-gray-2 py-4 poppins-500 p-3 rounded-[10px] mt-1 w-full"
            type="password"
            placeholder="CVV"
            name="cvc"
            maxLength={3}
            onChange={handleChange}
            value={cardData.cvc}
            required
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.target.value = e.target.value.replace(/\D/g, '');
            }}
          />
        </div>
      </div>
      <div className="w-full flex mt-7">
        {isSaveButton ? (
          <>
            <p className="text-red-0 w-full"> {error}</p>
            <div className="w-full flex justify-end">
              <CustomButton colorClass={ButtonColor.DARK_BLUE} onClick={onClick}>
                Save
              </CustomButton>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default CreditCardForm;
