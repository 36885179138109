import React from 'react';
import { useSelector } from 'react-redux';
import CrossIcon from '../../Assets/Images/svg/newCrossIcon.svg';
import SearchIcon from '../../Assets/Images/svg/searchIcon.svg';
import { RootState } from '../../Store';
import { makeFirstLetterCapital } from '../../Utils/helperFunctions';
import DashboardLogout from './DashboardLogout';

type TitleCardProps = {
  title: string;
  icon: React.ComponentType<{ fill?: string }>;
  isSearchBar?: boolean;
  isFilter?: boolean;
  inputValue?: string;
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch?: () => void;
  dropdownValue?: string;
  onDropdownChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onSearchReset?: () => void;
};

const TitleCard: React.FC<TitleCardProps> = ({
  title,
  icon: IconComponent,
  isSearchBar = false,
  isFilter = false,
  inputValue,
  onInputChange,
  onSearch,
  dropdownValue,
  onDropdownChange,
  onSearchReset,
}) => {
  const email = useSelector((state: RootState) => state.signup.customer?.customer?.user?.email);
  const userName = useSelector(
    (state: RootState) => state.signup.customer?.customer?.user?.firstName,
  );
  const adminEmail = useSelector(
    (state: RootState) => state.admin?.adminUser?.customer?.user?.email,
  );
  const adminUserName = useSelector(
    (state: RootState) => state.admin?.adminUser?.customer?.user?.firstName,
  );

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onSearch) {
      onSearch();
    }
  };

  return (
    <div className="bg-white-0 border rounded-[10px] border-gray-2 px-[20px] py-[5px] mb-[10px] flex flex-row items-center justify-between">
      <div className="flex items-center">
        <div className="w-[40px] h-[40px] flex items-center">
          <IconComponent />
        </div>
        <h1 className="text-center text-blue-1 text-2xl poppins-700 whitespace-nowrap">{title}</h1>
      </div>

      {isSearchBar && (
        <div className="flex items-center justify-between p-2 border border-gray-2 rounded-[4px] focus-within:outline focus-within:outline-blue-1 outline-2 h-[40px]">
          <button onClick={onSearch}>
            <img className="w-[18px] h-[18px]" src={SearchIcon} alt="" />
          </button>
          <input
            className="px-2 w-[200px] outline-none"
            name="searchBar"
            type="text"
            placeholder="Search"
            value={inputValue}
            onChange={onInputChange}
            onKeyPress={handleKeyPress}
            maxLength={150}
          />
          <button onClick={onSearchReset} className={inputValue ? 'visible' : 'invisible'}>
            <img className="w-[18px] h-[18px]" src={CrossIcon} alt="" />
          </button>
        </div>
      )}

      {isFilter && (
        <select
          className="border border-gray-2 p-2 w-[190px] rounded-[4px] outline-blue-1 h-[40px] br-2"
          value={dropdownValue}
          onChange={onDropdownChange}
        >
          <option value="active">Active</option>
          <option value="suspended">Suspended</option>
          <option value="cancelled">Cancelled</option>
          <option value="pending-cancellation">Pending Cancellation</option>
        </select>
      )}

      <div className="flex flex-col justify-center items-start">
        <h1 className="text-xl text-gray-1 font-semibold">
          {adminUserName ? makeFirstLetterCapital(adminUserName) : makeFirstLetterCapital(userName)}
        </h1>
        <DashboardLogout email={adminEmail || email} />
      </div>
    </div>
  );
};

export default TitleCard;
