import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { URLState } from '../Types';

const initialState: URLState = {
  URL_INSTRUCTIONS_CREATE_PROJECT: 'https://console.cloud.google.com/projectcreate',
  URL_INSTRUCTIONS_GROUP:
    'https://support.google.com/a/topic/25840?hl=en&ref_topic=25838&sjid=5729334036795773600-EU',
  URL_INSTRUCTIONS_GROUP_ORG: 'https://support.google.com/a/answer/9400082?hl=en',
  URL_PRIVACY_POLICY: 'https://content.cntxt.com/privacy_policy',
  URL_SUBSCRIPTION_AGREEMENT: 'https://content.cntxt.com/agreement_online',
  URL_TERMS_OF_USE: 'https://content.cntxt.com/data-processing-agreement',
  URL_CNTXT_SUPPORT: 'https://cntxt.my.site.com/',
};

export const signupSlice = createSlice({
  name: 'urls',
  initialState,
  reducers: {
    fetchURLs: (state, action: PayloadAction<URLState>) => {
      const urls = action.payload;
      state.URL_INSTRUCTIONS_CREATE_PROJECT = urls.URL_INSTRUCTIONS_CREATE_PROJECT;
      state.URL_INSTRUCTIONS_GROUP = urls.URL_INSTRUCTIONS_GROUP;
      state.URL_INSTRUCTIONS_GROUP_ORG = urls.URL_INSTRUCTIONS_GROUP_ORG;
      state.URL_PRIVACY_POLICY = urls.URL_PRIVACY_POLICY;
      state.URL_SUBSCRIPTION_AGREEMENT = urls.URL_SUBSCRIPTION_AGREEMENT;
      state.URL_TERMS_OF_USE = urls.URL_TERMS_OF_USE;
      state.URL_CNTXT_SUPPORT = urls.URL_CNTXT_SUPPORT;
    },
    resetURLs: (state) => {
      state.URL_INSTRUCTIONS_CREATE_PROJECT = initialState.URL_INSTRUCTIONS_CREATE_PROJECT;
      state.URL_INSTRUCTIONS_GROUP = initialState.URL_INSTRUCTIONS_GROUP;
      state.URL_INSTRUCTIONS_GROUP_ORG = initialState.URL_INSTRUCTIONS_GROUP_ORG;
      state.URL_PRIVACY_POLICY = initialState.URL_PRIVACY_POLICY;
      state.URL_SUBSCRIPTION_AGREEMENT = initialState.URL_SUBSCRIPTION_AGREEMENT;
      state.URL_TERMS_OF_USE = initialState.URL_TERMS_OF_USE;
      state.URL_CNTXT_SUPPORT = initialState.URL_CNTXT_SUPPORT;
    },
  },
});

export const { fetchURLs, resetURLs } = signupSlice.actions;

export default signupSlice.reducer;
