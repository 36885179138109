import { io } from 'socket.io-client';

const URL = process.env.REACT_APP_SOCKET_BASE_URL ?? 'https://gce-api.dev-cobs.cntxt.com';

export const socketConnection = (userToken: string) => {
  const socket = io(URL, {
    path: '/notification',
    extraHeaders: {
      Authorization: `Bearer ${userToken}`,
    },
  });
  return socket;
};
