import NafathLogo from '../../../../Assets/Images/svg/error.svg';
import CustomButton from '../../../../Components/Common/CustomButton';
import Modal from '../../../../Components/Common/Modal';
import { ButtonColor } from '../../../../Utils/constants';
import { identificationType } from '../../../../Utils/helperFunctions';

type ModalTypes = {
  identificationNumber: string;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  heading: string;
  description: string;
  onReverifyClick: () => void;
};

const NafathError = ({
  identificationNumber,
  setIsModalOpen,
  heading,
  description,
  onReverifyClick,
}: ModalTypes) => {
  return (
    <Modal title="" setIsModalOpen={setIsModalOpen}>
      <div className="w-full flex items-center justify-center">
        <img className="mt-2" src={NafathLogo} alt="Nafath logo" />
      </div>
      <h3 className="poppins-700 text-[24px] text-center mt-4">{heading}</h3>
      <p className="poppins-400 text-gray-7 opacity-70 max-w-[600px] my-2 text-center w-[82%] mx-auto">
        {description} (for {identificationType(identificationNumber)}:{' '}
        <strong>{identificationNumber}</strong>).
      </p>
      <div className="flex justify-center gap-x-5 mt-8 mb-4">
        <CustomButton
          className="px-8 py-3 font-bold min-w-[45%]"
          colorClass={ButtonColor.White}
          onClick={() => setIsModalOpen(false)}
        >
          Cancel
        </CustomButton>
        <CustomButton
          className="px-8 py-3 font-bold min-w-[45%]"
          colorClass={ButtonColor.DARK_BLUE}
          onClick={onReverifyClick}
        >
          Reverify
        </CustomButton>
      </div>
    </Modal>
  );
};

export default NafathError;
