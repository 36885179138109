import { Route, Routes } from 'react-router-dom';
import BillingHistory from '../Pages/BillingHistory';
import Dashboard from '../Pages/Dashboard';
import Login from '../Pages/Login';
import MangeCustomer from '../Pages/ManageCustomers';
import ManagePayment from '../Pages/ManagePayment';
import ManageUser from '../Pages/ManageUser';
import Signup from '../Pages/Signup';
import Subscription from '../Pages/Subscription';
import { Navigation } from '../Utils/constants';
import NotFound from './NotFound';
import { PrivateRoute, PublicRoute } from './RouteType';

const AppRouter = () => (
  <Routes>
    <Route
      path={Navigation.DEFAULT}
      element={
        <PublicRoute>
          <Login />
        </PublicRoute>
      }
    />
    <Route
      path={Navigation.SIGN_UP}
      element={
        <PublicRoute>
          <Signup />
        </PublicRoute>
      }
    />
    <Route
      path={Navigation.DASHBOARD}
      element={
        <PrivateRoute pathLink={Navigation.DASHBOARD}>
          <Dashboard />
        </PrivateRoute>
      }
    />
    <Route
      path={Navigation.BILLING_HISTORY}
      element={
        <PrivateRoute pathLink={Navigation.BILLING_HISTORY}>
          <BillingHistory />
        </PrivateRoute>
      }
    />
    <Route
      path={Navigation.MANAGE_USERS}
      element={
        <PrivateRoute pathLink={Navigation.MANAGE_USERS}>
          <ManageUser />
        </PrivateRoute>
      }
    />
    <Route
      path={Navigation.MY_CARDS}
      element={
        <PrivateRoute pathLink={Navigation.MY_CARDS}>
          <ManagePayment />
        </PrivateRoute>
      }
    />
    <Route
      path={Navigation.SUBSCRIPTION}
      element={
        <PrivateRoute pathLink={Navigation.SUBSCRIPTION}>
          <Subscription />
        </PrivateRoute>
      }
    />
    <Route
      path={Navigation.MANAGE_CUSTOMERS}
      element={
        <PrivateRoute pathLink={Navigation.MANAGE_CUSTOMERS}>
          <MangeCustomer />
        </PrivateRoute>
      }
    />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
export default AppRouter;
