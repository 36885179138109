import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logoutIcon from '../../Assets/Images/svg/logoutIcon.svg';
import { resetAdmin } from '../../Reducers/adminReducer';
import { resetCardData } from '../../Reducers/cardReducer';
import { resetCustomers } from '../../Reducers/customersReducer';
import { resetDashboardStats } from '../../Reducers/dashboardReducer';
import { resetInvoices } from '../../Reducers/invoicesReducer';
import { resetReducer } from '../../Reducers/signupReducer';
import { resetSubscriptionData } from '../../Reducers/subscriptionReducer';
import { resetURLs } from '../../Reducers/urlsReducer';
import { resetUsers } from '../../Reducers/usersReducer';

const LogoutButton = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(resetUsers());
    dispatch(resetInvoices());
    dispatch(resetDashboardStats());
    dispatch(resetSubscriptionData());
    dispatch(resetReducer());
    dispatch(resetCardData());
    dispatch(resetCustomers());
    dispatch(resetAdmin());
    dispatch(resetURLs());
    navigate('/');
  };

  return (
    <button className="flex items-center gap-3 cursor-pointer" onClick={handleLogout}>
      <img src={logoutIcon} alt="logout icon" />
      <p className="text-xs md:text-sm font-bold text-gray-1 opacity-70">Logout</p>
    </button>
  );
};

export default LogoutButton;
