import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultInvoice, Invoice, InvoicesState } from '../Types';

const initialState: InvoicesState = {
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
  invoices: [defaultInvoice] as Invoice[],
};

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    fetchInvoices: (state, action: PayloadAction<InvoicesState>) => {
      state.totalItems = action.payload.totalItems;
      state.totalPages = action.payload.totalPages;
      state.currentPage = action.payload.currentPage;
      state.invoices = action.payload.invoices;
    },
    resetInvoices: (state) => {
      state.totalItems = initialState.totalItems;
      state.totalPages = initialState.totalPages;
      state.currentPage = initialState.currentPage;
      state.invoices = initialState.invoices;
    },
  },
});

export const { resetInvoices, fetchInvoices } = invoicesSlice.actions;

export default invoicesSlice.reducer;
