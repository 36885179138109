import { ButtonColor } from '../../../Utils/constants';
import CustomButton from '../CustomButton';

type NavigationButtonProps = {
  active: boolean;
  onClick: () => void;
  title: string;
  icon: React.ComponentType;
  darkIcon: React.ComponentType;
  disable?: boolean;
};
const NavigationButton = ({
  active,
  onClick,
  title,
  icon: IconComponent,
  darkIcon: DarkIconComponent,
  disable,
}: NavigationButtonProps) => {
  return active ? (
    <CustomButton
      colorClass={ButtonColor.DARK_BLUE}
      className={'min-w-[226px] px-[31px] py-2 flex items-stretch'}
      onClick={onClick}
    >
      <div className="gap-7 flex ">
        <div className="max-w-[24px]">
          <IconComponent />
        </div>

        <h1 className="text-center text-white-0 text-base poppins-500 whitespace-nowrap">
          {title}
        </h1>
      </div>
    </CustomButton>
  ) : (
    <CustomButton
      colorClass={ButtonColor.White}
      className={'min-w-[226px] border-none px-[31px] py-2 flex items-stretch'}
      onClick={onClick}
      disabled={disable}
    >
      <div className="gap-7 flex">
        <div className="max-w-[24px]">
          <DarkIconComponent />
        </div>
        <h1 className="text-center text-gray-1 opacity-[70%] text-base poppins-500">{title}</h1>
      </div>
    </CustomButton>
  );
};

export default NavigationButton;
