import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerObject, defaultCustomer } from '../Types';

export interface SignupState {
  accountType: string;
  crnError: string;
  onboardingStep: string;
  customer: CustomerObject;
  userToken: string;
  spinner: boolean;
  CrnVerification: boolean;
  // Nafath enable status
  verificationEnabled: boolean;
}

const initialState: SignupState = {
  accountType: '',
  crnError: '',
  onboardingStep: '',
  customer: { customer: defaultCustomer },
  userToken: '',
  spinner: false,
  CrnVerification: false,
  verificationEnabled: false,
};

export const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    updateAccountType: (state, action: PayloadAction<string>) => {
      state.accountType = action.payload;
    },
    updateOnboardingStep: (state, action: PayloadAction<string>) => {
      state.onboardingStep = action.payload;
    },
    updateCrnError: (state, action: PayloadAction<string>) => {
      state.crnError = action.payload;
    },
    updateCrnVerification: (state, action: PayloadAction<boolean>) => {
      state.CrnVerification = action.payload;
    },
    updateUserToken: (state, action: PayloadAction<string>) => {
      state.userToken = action.payload;
    },
    resetReducer: (state) => {
      state.accountType = initialState.accountType;
      state.onboardingStep = initialState.onboardingStep;
      state.customer = { customer: defaultCustomer };
      state.spinner = initialState.spinner;
      state.userToken = initialState.userToken;
      state.crnError = initialState.crnError;
      state.verificationEnabled = initialState.verificationEnabled;
    },
    fetchCustomerDetails: (state, action: PayloadAction<CustomerObject>) => {
      state.customer = action.payload;
    },
    updateSpinner: (state, action: PayloadAction<boolean>) => {
      state.spinner = action.payload;
    },
    updateNafathStatus: (state, action: PayloadAction<boolean>) => {
      state.verificationEnabled = action.payload;
    },
  },
});

export const {
  updateAccountType,
  updateCrnError,
  updateOnboardingStep,
  updateCrnVerification,
  resetReducer,
  updateUserToken,
  fetchCustomerDetails,
  updateSpinner,
  updateNafathStatus,
} = signupSlice.actions;

export default signupSlice.reducer;
