import ArrowIcon from '../../../Assets/Images/svg/arrowIcon.svg';
type InfoAttributeProps = {
  title: string;
  value: string;
  tagline?: string;
  subValue?: string;
  buttonText?: string;
  update?: () => void;
  textColor?: string;
};
const InfoAttribute = ({
  title,
  value,
  subValue,
  tagline,
  update,
  buttonText,
  textColor,
}: InfoAttributeProps) => {
  return (
    <div className="flex flex-col items-start">
      <h2 className="text-base text-gray-7 poppins-500">{title}</h2>
      <h2 className={`text-[22px] poppins-700 text-gray-1 mt-0 ${textColor}`}>{value}</h2>
      {tagline && <h2 className="text-[18px] poppins-500 text-gray-1 opacity-[70%]">{tagline}</h2>}
      {subValue && (
        <p className="text-base text-gray-1 poppins-300 opacity-[70%] mt-0">{subValue}</p>
      )}
      {update && (
        <button className="flex flex-row items-center" onClick={update}>
          <p className="text-base poppins-300 text-blue-1 mr-[5px]">{buttonText}</p>
          <img src={ArrowIcon} alt="Arrow" />
        </button>
      )}
    </div>
  );
};
export default InfoAttribute;
