import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DashboardProps } from '../Types';

const defaultLastInvoice = {
  id: 0,
  customerId: 0,
  qoyodInvoiceId: null,
  description: null,
  billingMonth: '',
  thresholdInvoice: '',
  currency: '',
  grossAmount: 0,
  chargedAmount: 0,
  discount: null,
  netAmount: 0,
  resellerMargin: null,
  netPayableGoogle: null,
  supportLineItem: null,
  status: '',
  invoiceUrl: null,
  qoyod_reference: null,
  paymentFailedAttempts: null,
  lastPaymentFailureReason: null,
  paymentAttempts: null,
  last_bill_date: '',
  issuedAt: null,
  dueDate: null,
  chargedAt: null,
  createdAt: '',
  updatedAt: '',
};

const initialState: DashboardProps = {
  unbilledAmount: null,
  nextCharge: '',
  lastInvoice: defaultLastInvoice,
};

export const dashboardSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    fetchDashboardStats: (state, action: PayloadAction<DashboardProps>) => {
      state.unbilledAmount = action.payload.unbilledAmount;
      state.nextCharge = action.payload.nextCharge;
      state.lastInvoice = action.payload.lastInvoice;
    },
    resetDashboardStats: (state) => {
      state.unbilledAmount = initialState.unbilledAmount;
      state.nextCharge = initialState.nextCharge;
      state.lastInvoice = initialState.lastInvoice;
    },
  },
});

export const { fetchDashboardStats, resetDashboardStats } = dashboardSlice.actions;

export default dashboardSlice.reducer;
