import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddIcon from '../../Assets/Images/svg/addIcon.svg';
import DeleteIcon from '../../Assets/Images/svg/deleteIcon.svg';
import { ReactComponent as UserIcon } from '../../Assets/Images/svg/pageManageUsersIcon.svg';
import CustomButton from '../../Components/Common/CustomButton';
import DataTable from '../../Components/Common/DataTable';
import Modal from '../../Components/Common/Modal';
import NavigationBar from '../../Components/Common/NavigationBar/index';
import Select from '../../Components/Common/Select';
import Spinner from '../../Components/Common/Spinner';
import TitleCard from '../../Components/Common/TitleCard';
import { AppDispatch, RootState } from '../../Store';
import { deleteUsersAsync, fetchUsersAsync, inviteUserAsync } from '../../Utils/api';
import {
  Admin,
  ButtonColor,
  DashBoardTab,
  MANAGE_USERS_TABLE_HEADERS,
  PER_PAGE_LIMIT_USERS,
  Role,
  UserRoles,
} from '../../Utils/constants';
import {
  formatDateString,
  makeFirstLetterCapital,
  validateEmail,
} from '../../Utils/helperFunctions';

type User = {
  name: string;
  email: string;
  created_on: string;
  status: string;
};

const ManageUsers = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userRole = useSelector((state: RootState) => state.admin?.adminUser?.customer?.user?.role);
  const role = useSelector((state: RootState) => state.admin?.adminUser?.customer?.user?.role);
  const customerId = useSelector((state: RootState) => state.admin?.currentCustomer);

  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
  const usersDetails = useSelector((state: RootState) => state.users);
  const totalItems = useSelector((state: RootState) => state.users?.totalItems);
  const [isInitialState, setIsInitialState] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [modalError, setModalError] = useState('');
  const [users, setUsers] = useState<User[]>([]);

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const users: User[] = usersDetails?.users.map((user, index) => ({
      name: `${user?.firstName || ''} ${user?.lastName || ''}`,
      email: user?.email || '',
      created_on: formatDateString(user?.createdAt) || '',
      status: user?.status === 'pending' ? 'Inactive' : makeFirstLetterCapital(user.status) || '',
      action: index === 0 ? null : user?.id,
    }));
    setUsers(users);
  }, [usersDetails]);

  useEffect(() => {
    console.log('Initial Render role', role);
    if (isInitialState) {
      if (role === Role.ADMIN) {
        dispatch(
          fetchUsersAsync(
            0,
            PER_PAGE_LIMIT_USERS,
            setIsLoading,
            setErrorMessage,
            navigate,
            true,
            customerId,
          ),
        );
      } else {
        dispatch(
          fetchUsersAsync(0, PER_PAGE_LIMIT_USERS, setIsLoading, setErrorMessage, navigate, false),
        );
      }

      setIsInitialState(false);
    }
  }, []);

  const onPreviousPageClick = () => {
    if (currentPage === 0) return;
    if (role === Role.ADMIN) {
      dispatch(
        fetchUsersAsync(
          currentPage - 1,
          PER_PAGE_LIMIT_USERS,
          setIsLoading,
          setErrorMessage,
          navigate,
          true,
          customerId,
        ),
      );
    } else {
      dispatch(
        fetchUsersAsync(
          currentPage - 1,
          PER_PAGE_LIMIT_USERS,
          setIsLoading,
          setErrorMessage,
          navigate,
          false,
        ),
      );
    }

    setCurrentPage(currentPage - 1);
  };

  const onNextPageClick = () => {
    if (currentPage === usersDetails.totalPages - 1) return;
    if (role === Role.ADMIN) {
      dispatch(
        fetchUsersAsync(
          currentPage + 1,
          PER_PAGE_LIMIT_USERS,
          setIsLoading,
          setErrorMessage,
          navigate,
          true,
          customerId,
        ),
      );
    } else {
      dispatch(
        fetchUsersAsync(
          currentPage + 1,
          PER_PAGE_LIMIT_USERS,
          setIsLoading,
          setErrorMessage,
          navigate,
          false,
        ),
      );
    }

    setCurrentPage(currentPage + 1);
  };

  const onDeleteUserClick = (userId: string | number) => {
    let pageNumber;
    if (currentPage !== 0 && usersDetails.users?.length === 1) {
      pageNumber = currentPage - 1;
    } else {
      pageNumber = currentPage;
    }

    setErrorMessage('');

    if (role === Role.ADMIN) {
      dispatch(
        deleteUsersAsync({
          userId,
          currentPage: pageNumber,
          limit: PER_PAGE_LIMIT_USERS,
          setIsLoading,
          setErrorMessage,
          navigate,
          isAdmin: true,
          customerId,
        }),
      );
    } else {
      dispatch(
        deleteUsersAsync({
          userId,
          currentPage: pageNumber,
          limit: PER_PAGE_LIMIT_USERS,
          setIsLoading,
          setErrorMessage,
          navigate,
          isAdmin: false,
        }),
      );
    }
    setCurrentPage(pageNumber);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value.toLowerCase();
    setEmail(email);

    if (!validateEmail(email)) {
      setModalError('Please enter a valid email address.');
    } else {
      setModalError('');
    }
  };

  const onAddInviteUserButton = () => {
    setIsInviteUserModalOpen(false);

    let pageNumber;
    if (usersDetails.users?.length === PER_PAGE_LIMIT_USERS) {
      pageNumber = currentPage + 1;
    } else {
      pageNumber = currentPage;
    }
    setErrorMessage('');

    if (role === Role.ADMIN) {
      dispatch(
        inviteUserAsync({
          email,
          currentPage: pageNumber,
          limit: PER_PAGE_LIMIT_USERS,
          setIsLoading,
          setErrorMessage: setModalError,
          navigate,
          setIsModalOpen: setIsInviteUserModalOpen,
          isAdmin: true,
          customerId,
        }),
      );
    } else {
      dispatch(
        inviteUserAsync({
          email,
          currentPage: pageNumber,
          limit: PER_PAGE_LIMIT_USERS,
          setIsLoading,
          setErrorMessage: setModalError,
          navigate,
          setIsModalOpen: setIsInviteUserModalOpen,
          isAdmin: false,
        }),
      );
    }
    setCurrentPage(pageNumber);
  };

  const onPageNumberClick = (pageNumber: number) => {
    if (role === Role.ADMIN) {
      dispatch(
        fetchUsersAsync(
          pageNumber,
          PER_PAGE_LIMIT_USERS,
          setIsLoading,
          setErrorMessage,
          navigate,
          true,
          customerId,
        ),
      );
    } else {
      dispatch(
        fetchUsersAsync(
          pageNumber,
          PER_PAGE_LIMIT_USERS,
          setIsLoading,
          setErrorMessage,
          navigate,
          false,
        ),
      );
    }

    setCurrentPage(pageNumber);
  };
  return (
    <div className="flex pb-[20px] px-[23px]">
      <div>
        <NavigationBar tab={DashBoardTab.MANAGE_USERS} />
      </div>
      <div className="flex flex-col ml-[33px] w-screen">
        <TitleCard title="Manage Users" icon={UserIcon} />
        <div
          className={`bg-white-0 border-t rounded-tl-[10px] rounded-tr-[10px] border-t-gray-3 border-r-gray-3 border-l-gray-3 border-l border-r  px-3.5 py-3 flex flex-row justify-end ${
            (totalItems === 0 || isLoading) && 'border-b border-b-gray-3 rounded-b-[10px]'
          } `}
        >
          <button
            onClick={() => {
              setModalError('');
              setIsInviteUserModalOpen(true);
            }}
            className="border-2 poppins-500 border-blue-1 rounded-lg px-6 py-2 text-blue-1 font-bold font-sm flex justify-center items-center gap-3"
          >
            <img src={AddIcon} alt="Add User Icon" />
            Add New user
          </button>
        </div>
        {isLoading && <Spinner adjustContainerHeight />}
        {totalItems > 0 && !isLoading && (
          <DataTable
            tableHeader={MANAGE_USERS_TABLE_HEADERS}
            data={users}
            height="h-[660px]"
            totalPages={usersDetails?.totalPages}
            currentPage={usersDetails?.currentPage}
            adjustTopBorder
            onRightArrowClick={onNextPageClick}
            onLeftArrowClick={onPreviousPageClick}
            onButtonClick={onDeleteUserClick}
            onPageNumberClick={onPageNumberClick}
          >
            <img src={DeleteIcon} alt="Delete Icon" />
          </DataTable>
        )}
        {totalItems === 0 && !isLoading && (
          <p className="text-[16px] text-center text-red-0 ">No data available</p>
        )}

        {isInviteUserModalOpen && (
          <Modal title="Send Invite" setIsModalOpen={setIsInviteUserModalOpen}>
            <div className="flex flex-col gap-3">
              <div>
                <h3 className="text-sm text-left leading-loose text-gray-1">Email</h3>
                <input
                  className="border border-gray-2 p-2 w-full"
                  type="text"
                  placeholder="abc@gmail.com"
                  onChange={handleEmailChange}
                />
              </div>
              {userRole === Admin && (
                <div>
                  <h3 className="text-sm font-bold text-left leading-loose text-gray-1">Role</h3>
                  <Select options={UserRoles} disabled />
                </div>
              )}
              <div className="flex justify-center items-center">
                {modalError ? (
                  <p className="text-[14px] text-center text-red-0">{modalError}</p>
                ) : null}
                <CustomButton
                  className="ml-auto min-w-[120px]"
                  onClick={onAddInviteUserButton}
                  colorClass={ButtonColor.DARK_BLUE}
                >
                  Send
                </CustomButton>
              </div>
            </div>
          </Modal>
        )}
        {errorMessage ? <p className="text-[14px] text-center text-red-0">{errorMessage}</p> : null}
      </div>
    </div>
  );
};
export default ManageUsers;
