import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LogoSection from '../../../Components/Common/LogoSection';
import Spinner from '../../../Components/Common/Spinner';
import Stepper from '../../../Components/Common/Stepper';
import StepperMobile from '../../../Components/Common/StepperMobile';
import { updateOnboardingStep } from '../../../Reducers/signupReducer';
import { AppDispatch, RootState } from '../../../Store';
import { updateSupportPackageAsync } from '../../../Utils/api';
import {
  BASIC,
  BUSINESS,
  INDIVIDUAL,
  ONBOARDING_STEPS,
  supportPackages,
} from '../../../Utils/constants';
import ButtonsSection from '../Common/ButtonsSection';
import PackageContainer from '../Common/PackageContainer';

const SupportPackage = () => {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const accountType = useSelector((state: RootState) => state.signup.accountType);
  const customerDetails = useSelector((state: RootState) => state.signup.customer?.customer);
  const [selectedPackageName, setSelectedPackageName] = useState(
    customerDetails.package ? customerDetails.package : BASIC.toLowerCase(),
  );

  const handleBack = () => {
    if (accountType === BUSINESS) {
      dispatch(updateOnboardingStep(ONBOARDING_STEPS.BUSINESS_INFORMATION));
    } else {
      dispatch(updateOnboardingStep(ONBOARDING_STEPS.CONTACT_INFORMATION));
    }
  };
  const handleNext = () => {
    dispatch(
      updateSupportPackageAsync(
        selectedPackageName.toLowerCase(),
        setIsLoading,
        setErrorMessage,
        navigate,
      ),
    );
  };

  return (
    <div className="w-full h-full flex items-stretch lg:items-center justify-center">
      {isLoading && <Spinner />}
      {!isLoading && (
        <div className="border-[1px] border-gray-3 rounded-lg py-4 px-8  bg-white-0 min-h-[680px] w-[98%] overflow-auto flex flex-col justify-between">
          <LogoSection />
          {accountType === BUSINESS ? (
            <Stepper type={BUSINESS} currentStep={2} />
          ) : (
            <Stepper type={INDIVIDUAL} currentStep={2} />
          )}
          <StepperMobile
            stepNumber={2}
            stepName="Support Package"
            nextStep={accountType === BUSINESS ? 'Upload Documents' : 'Payment Information'}
            totalSteps={accountType === BUSINESS ? 5 : 3}
          />
          <p className="text-black-0 poppins-500 text-sm leading-6 text-left">
            Elevate your Google Cloud experience with CNTXT Support, a certified support service
            backed by Google Cloud Premium-level support.
            <br className="md:flex hidden" /> CNTXT Support, with its local presence meeting KSA
            regulatory requirements, ensures a seamless cloud journey aligned with your needs.
          </p>
          <div className="flex flex-col md:flex-row gap-8 justify-center items-center pt-8 md:pt-12">
            {supportPackages.map((value) => (
              <button
                key={value.packageName}
                onClick={() => setSelectedPackageName(value.packageName.toLowerCase())}
                className={`cursor-pointer ${
                  selectedPackageName === value.packageName.toLowerCase()
                    ? 'translate-y-[-16px] border-2 rounded-[10px] transition duration-500'
                    : ''
                }`}
                style={{
                  borderColor:
                    selectedPackageName === value.packageName.toLowerCase()
                      ? value.color
                      : 'transparent', // Use the color prop for the border
                }}
              >
                <PackageContainer
                  key={value.packageName}
                  packageName={value.packageName}
                  price={value.price}
                  tagline={value.tagline}
                  pointOne={value.pointOne}
                  pointTwo={value.pointTwo}
                  commitments={value.commitments}
                  color={value.color}
                  isSelected={selectedPackageName === value.packageName.toLowerCase()}
                  isDisabled={false}
                />
              </button>
            ))}
          </div>
          <p className="text-black-0 poppins-500 text-sm leading-6 text-left">
            CNTXT Support does not cover Google Maps Support.
          </p>
          <ButtonsSection
            errorMessage={errorMessage}
            isBackButton={true}
            isNextButton={true}
            nextFunction={handleNext}
            backFunction={handleBack}
          />
        </div>
      )}
    </div>
  );
};

export default SupportPackage;
