import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LogoSection from '../../Components/Common/LogoSection';
import Spinner from '../../Components/Common/Spinner';
import { AppDispatch, RootState } from '../../Store';
import { updateInitialDetailsStepAsync } from '../../Utils/api';
import { BUSINESS } from '../../Utils/constants';
import ButtonsSection from './Common/ButtonsSection';

const InitialDetails = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const accountType = useSelector((state: RootState) => state.signup.accountType);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleNextStep = () => {
    dispatch(updateInitialDetailsStepAsync(setIsLoading, setErrorMessage, navigate));
  };

  return (
    <div className="bg-white-0 w-full h-full flex items-stretch justify-center my-0 md:my-2">
      {isLoading && <Spinner />}
      {!isLoading && (
        <div className="border-[1px] border-gray-3 rounded-lg w-[100%] md:w-[50vw] min-h-[680px] bg-white-0 flex flex-col items-center mt-0 md:mt-2  overflow-auto md:overflow-hidden [&::-webkit-scrollbar]:hidden">
          <div className="mt-4 mb-8 w-full px-8">
            <LogoSection hideText />
          </div>

          <div className="w-[85%] m-auto h-[90%] flex flex-col items-start">
            <p className="text-sm md:text-base text-left text-gray-16 poppins-400 py-2">
              Google Cloud is partnering with{' '}
              <a
                className="underline"
                target="_blank"
                rel="noopener noreferrer"
                href="https://cntxt.com/products/google-cloud-solutions/"
              >
                CNTXT
              </a>
              , as an exclusive reseller in the region, to bring Google Cloud to the Kingdom of
              Saudi Arabia. If you have a KSA billing address, you are required to purchase Google
              Cloud services for the Dammam me-central2 region or any other Google Cloud region
              through CNTXT or other authorized Google Cloud resellers who resell in the KSA via
              CNTXT.
            </p>
            <p className="text-sm md:text-base poppins-400 text-left text-gray-16 py-2 mb-4">
              If you are looking to enter into an offline agreement or require additional
              information about GCP products and services, contact us{' '}
              <a
                className="underline"
                target="_blank"
                rel="noopener noreferrer"
                href="https://cntxt.com/products/google-cloud-solutions/"
              >
                here
              </a>
              . If you would like to procure GCP using your credit card, continue with the steps
              outlined in this portal.
            </p>
            <h3 className="mt-3 text-lg text-gray-16 poppins-500">Country</h3>
            <div className="flex w-[280px] md:w-[400px] poppins-400 h-[44px] p-2 items-center rounded-md bg-gray-4 text-gray-1 text-opacity-70 mt-2">
              Saudi Arabia
            </div>
            <div className="w-full h-px bg-gray-17 mb-[23px] mt-[23px]" />
            <h3 className="text-lg poppins-500 text-gray-16 leading-6">Account Type</h3>
            {accountType === BUSINESS ? (
              <p className=" text-sm md:text-base poppins-400 text-left text-gray-1">
                This permanent setting is used for tax and identity verification. The
                <span className="font-bold"> Business</span> account type is for educational
                institutions, partnerships, organizations and businesses.
              </p>
            ) : (
              <p className=" text-sm md:text-base poppins-400 text-left text-gray-16">
                This permanent setting is used for tax and identity verification. The
                <span className="font-bold"> Individual </span>
                account type is for everyone using personal gmail account instead of business
                domain.
              </p>
            )}

            <div className="flex w-[280px] md:w-[400px] poppins-400 h-[44px] p-2 items-center rounded-md bg-gray-4 text-gray-1 text-opacity-70 mt-2">
              {accountType === BUSINESS ? 'Business' : 'Individual'}
            </div>
            <div className="w-full pt-12 pb-4">
              <ButtonsSection
                errorMessage={errorMessage}
                isDivider={false}
                isBackButton={false}
                isNextButton={true}
                nextFunction={handleNextStep}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default InitialDetails;
