import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Card } from '../Types';
import { formatExpiryDate } from '../Utils/helperFunctions';

const defaultCard: Card = {
  id: '',
  status: '',
  paymentIdentifier: '',
  last4: '',
  expiryDate: '',
  type: '',
  cardHolderName: '',
};

export interface CardState {
  cards: Card[];
  verifyingCard: Card;
  cardType: string;
}

const initialState: CardState = {
  cards: [],
  verifyingCard: defaultCard,
  cardType: '',
};

export const cardSlice = createSlice({
  name: 'card',
  initialState,
  reducers: {
    addCards: (state, action: PayloadAction<Card[]>) => {
      const newCardsArray = action.payload.map((item) => {
        return {
          id: item.id,
          status: item.status,
          expiryDate: formatExpiryDate(item.expiryDate),
          paymentIdentifier: item.paymentIdentifier,
          last4: item.last4,
          type: item.type,
          cardHolderName: item.cardHolderName,
        };
      });
      state.cards = newCardsArray;
    },
    addCardDetails: (state, action: PayloadAction<Card>) => {
      const card: Card = {
        id: action.payload.id,
        status: action.payload.status,
        expiryDate: formatExpiryDate(action.payload.expiryDate),
        paymentIdentifier: action.payload.paymentIdentifier,
        last4: action.payload.last4,
        type: action.payload.type,
        cardHolderName: action.payload.cardHolderName,
      };
      const newCardsArray = [...state.cards, card];
      state.cards = newCardsArray;
    },
    addVerifyingCard: (state, action: PayloadAction<Card>) => {
      const card: Card = {
        id: action.payload.id,
        status: action.payload.status,
        expiryDate: action.payload.expiryDate,
        paymentIdentifier: action.payload.paymentIdentifier,
        last4: action.payload.last4,
        type: action.payload.type,
        cardHolderName: action.payload.cardHolderName,
      };
      state.verifyingCard = card;
    },
    addCardType: (state, action: PayloadAction<string>) => {
      state.cardType = action.payload;
    },
    updateCard: (state, action: PayloadAction<{ id: string; status: string }>) => {
      const { id, status } = action.payload;
      const indexToUpdate = state.cards.findIndex((card) => card.id === id);

      if (indexToUpdate !== -1) {
        state.cards[indexToUpdate].status = status;
        const newCardsArray = [...state.cards];
        state.cards = newCardsArray;
      }
    },
    removeCard: (state, action: PayloadAction<string>) => {
      state.cards = state.cards.filter((card) => card.id !== action.payload);
    },
    resetCardData: (state) => {
      state.cards = [];
      state.verifyingCard = initialState.verifyingCard;
      state.cardType = initialState.cardType;
    },
    resetVerifyingCardData: (state) => {
      state.verifyingCard = initialState.verifyingCard;
    },
  },
});

export const {
  addCardDetails,
  addVerifyingCard,
  addCardType,
  addCards,
  updateCard,
  removeCard,
  resetCardData,
  resetVerifyingCardData,
} = cardSlice.actions;

export default cardSlice.reducer;
