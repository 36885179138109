import { useState } from 'react';
import DropdownIcon from '../../Assets/Images/svg/dropdownIcon.svg';
import { makeFirstLetterCapital } from '../../Utils/helperFunctions';

type Props = {
  options: string[];
  disabled?: boolean;
};
const Select = ({ options, disabled = false }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(makeFirstLetterCapital(options[0]));

  const handleSelect = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block w-full">
      <button
        onClick={() => (!disabled ? setIsOpen(!isOpen) : null)}
        className={`flex justify-between items-center text-gray-1 opacity-80 appearance-none w-full border border-gray-2 p-2 rounded-lg ${
          !disabled ? 'hover:border-gray-1' : 'cursor-default'
        }  bg-white-0`}
      >
        {selectedOption}
        <img src={DropdownIcon} alt="dropdown icon" className="ml-2" />
      </button>
      {isOpen && (
        <div className="absolute flex flex-col w-full mt-2 border border-gray-2 rounded-lg bg-white-0">
          {options.map((option) => (
            <button
              key={option}
              onClick={() => handleSelect(option)}
              className="text-left p-2 text-gray-1 opacity-80 hover:opacity-50 cursor-pointer"
            >
              {makeFirstLetterCapital(option)}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
