import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as SubscriptionIcon } from '../../Assets/Images/svg/pageSubscriptionIcon.svg';
import CustomButton from '../../Components/Common/CustomButton';
import NavigationBar from '../../Components/Common/NavigationBar/index';
import Spinner from '../../Components/Common/Spinner';
import TitleCard from '../../Components/Common/TitleCard';
import { AppDispatch, RootState } from '../../Store';
import {
  cancelSubscriptionAsync,
  fetchInvoicesAsync,
  getCustomerPackageDetailsAsync,
  resubscribeAsync,
} from '../../Utils/api';
import {
  ButtonColor,
  DashBoardTab,
  PER_PAGE_LIMIT,
  Role,
  Status,
  StatusReason,
  SubscriptionStatus,
} from '../../Utils/constants';
import { formatDateString, makeFirstLetterCapital } from '../../Utils/helperFunctions';
import InfoAttribute from '../Dashboard/InformationSummary/InfoAtrribute';
import SubscriptionModal from './SubscriptionModal';

type SubscriptionInfoProps = {
  status: string;
  activationDate: string;
  createdAt: string;
  packageName: string;
};

const SubscriptionInfo = ({
  status,
  activationDate,
  createdAt,
  packageName,
}: SubscriptionInfoProps) => {
  const activationDateParsed = activationDate ? new Date(activationDate) : null;
  const createdAtParsed = new Date(createdAt);

  const dateToUse =
    activationDateParsed && activationDateParsed > createdAtParsed
      ? activationDateParsed
      : createdAtParsed;

  const dateToUseString = dateToUse.toISOString();

  const updatedTagline = `${
    activationDate ? `Subscribed on ${formatDateString(dateToUseString)}` : ''
  }`;
  return (
    <div className="flex flex-row w-full justify-between pb-3">
      <InfoAttribute
        title="Status"
        value={status ? makeFirstLetterCapital(status).replaceAll('-', ' ') : 'Not Available'}
        textColor={status === SubscriptionStatus.CANCELLED ? 'text-red-0' : ''}
      />
      <InfoAttribute
        title="Active Since"
        value={createdAt ? formatDateString(createdAt) : 'Not Available'}
      />

      <InfoAttribute
        title="Support Package"
        value={packageName ? makeFirstLetterCapital(packageName) : 'Not Available'}
        tagline={updatedTagline}
      />
    </div>
  );
};

const Subscription = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const {
    status,
    package: subscriptionPackage,
    statusDetails,
    subscriptionDate,
  } = useSelector((state: RootState) => state.subscription);
  const role = useSelector((state: RootState) => state.admin?.adminUser?.customer?.user?.role);
  const customerId = useSelector((state: RootState) => state.admin?.currentCustomer);
  const activationDate = useSelector(
    (state: RootState) => state.signup?.customer?.customer?.activationDate,
  );
  const invoicesDetails = useSelector((state: RootState) => state.invoices);
  const [isInvoicePending, setIsInvoicePending] = useState(false);
  const [isCancelSubscriptionModalOpen, setIsCancelSubscriptionModalOpen] = useState(false);
  const [isResubscribeModalOpen, setIsResubscribeModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const invoice = invoicesDetails.invoices.find(
      (invoice) => invoice.status === Status.UNPAID || invoice.status === Status.FAILED,
    );
    if (invoice) setIsInvoicePending(true);
  }, [invoicesDetails]);

  useEffect(() => {
    if (role === Role.ADMIN) {
      dispatch(
        getCustomerPackageDetailsAsync(setIsLoading, setErrorMessage, navigate, true, customerId),
      );
      dispatch(
        fetchInvoicesAsync(
          0,
          PER_PAGE_LIMIT,
          setIsLoading,
          setErrorMessage,
          navigate,
          true,
          customerId,
        ),
      );
    } else {
      dispatch(getCustomerPackageDetailsAsync(setIsLoading, setErrorMessage, navigate, false));
      dispatch(
        fetchInvoicesAsync(0, PER_PAGE_LIMIT, setIsLoading, setErrorMessage, navigate, false),
      );
    }
  }, []);

  const onCancelSubscriptionClick = () => {
    setIsCancelSubscriptionModalOpen(false);

    if (role === Role.ADMIN) {
      dispatch(cancelSubscriptionAsync(setIsLoading, setErrorMessage, navigate, true, customerId));
    } else {
      dispatch(cancelSubscriptionAsync(setIsLoading, setErrorMessage, navigate, false));
    }
  };

  const onResubscribeClick = () => {
    setIsResubscribeModalOpen(false);

    if (role === Role.ADMIN) {
      dispatch(resubscribeAsync(setIsLoading, setErrorMessage, navigate, true, customerId));
    } else {
      dispatch(resubscribeAsync(setIsLoading, setErrorMessage, navigate, false));
    }
  };

  const getButtonClass = (status: string) => {
    if (status === SubscriptionStatus.CANCELLED) {
      return ButtonColor.White;
    }
    return ButtonColor.Red;
  };

  const getModalFunction = (status: string) => {
    if (status === SubscriptionStatus.CANCELLED) {
      return setIsResubscribeModalOpen;
    }
    return setIsCancelSubscriptionModalOpen;
  };

  const suspendedReason = statusDetails ? statusDetails.reason : null;

  const unpaidInvoice =
    status === SubscriptionStatus.ACTIVE &&
    suspendedReason !== StatusReason.PENDING_REVIEW &&
    isInvoicePending;
  const pendingReview =
    (status === SubscriptionStatus.ACTIVE || status === SubscriptionStatus.SUSPENDED) &&
    suspendedReason === StatusReason.PENDING_REVIEW;
  const suspendedSecurity =
    status === SubscriptionStatus.SUSPENDED && suspendedReason === StatusReason.SECURITY;
  const suspendedFailedPayment =
    status === SubscriptionStatus.SUSPENDED && suspendedReason === StatusReason.FAILED_PAYMENT;

  // Disable cancel subscription button if any invoice "status" is 'pending' or 'failed'
  // or user "status" is 'active' yet 'pending-review'
  // or 'pending-cancellation'
  // or 'suspended' due to 'security', 'failed-payment' or 'pending-review' reasons
  const cancelDisabled =
    unpaidInvoice ||
    pendingReview ||
    status === SubscriptionStatus.PENDING_CANCELLATION ||
    suspendedSecurity ||
    suspendedFailedPayment;

  const errorDisplay = (text: string) => {
    return <p className="text-[10px] text-center text-red-0 max-w-[600px]">{text}</p>;
  };

  return (
    <div className="flex flex-row pb-[20px] px-[23px]">
      <div>
        <NavigationBar tab={DashBoardTab.SUBSCRIPTION} />
      </div>
      <div className="flex flex-col ml-[33px] w-full">
        <TitleCard title="Subscription" icon={SubscriptionIcon} />

        <div className="flex flex-col items-start p-6 bg-white-0 border rounded-[10px] border-gray-2 h-full gap-y-3">
          {isLoading && <Spinner adjustContainerHeight />}
          {!isLoading && (
            <>
              <SubscriptionInfo
                status={status}
                activationDate={subscriptionDate ?? ''}
                createdAt={activationDate || ''}
                packageName={subscriptionPackage}
              />
              <div className="w-full h-[1px] bg-gray-17"></div>
              <div className="flex flex-row gap-x-28 w-full">
                <div className="flex flex-col gap-y-4 w-full md:w-1/2">
                  <div className="flex flex-col gap-y-4 w-full">
                    <h3 className="text-sm poppins-500 text-left text-gray-1 -mb-2">
                      {status === SubscriptionStatus.CANCELLED
                        ? 'Re-subscribe'
                        : 'Cancel Subscription'}
                    </h3>
                    <p className="text-xs text-left text-gray-1 opacity-70 poppins-400">
                      {status === SubscriptionStatus.CANCELLED
                        ? 'To start all activity and usage you will have to Re-subscribe.'
                        : 'Please be advised that your CNTXT Google Cloud account will be deleted. After cancellation, all services and data associated with your account will be inaccessible. Also note that any outstanding balance will be charged before the account closure.'}
                    </p>
                    <div className="w-full flex justify-end mt-8">
                      <CustomButton
                        className="w-2/5 font-bold poppins-500"
                        onClick={() => getModalFunction(status)(true)}
                        colorClass={getButtonClass(status)}
                        disabled={cancelDisabled}
                      >
                        {status === SubscriptionStatus.CANCELLED
                          ? 'Re-subscribe'
                          : 'Cancel Subscription'}
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </div>
              {status === SubscriptionStatus.PENDING_CANCELLATION &&
                errorDisplay('Your subscription will be cancelled within 48 hours.')}
              {suspendedSecurity &&
                errorDisplay(
                  'Your account is currently suspended for security review. Account cancellation is not possible at this time.',
                )}
              {(suspendedFailedPayment || unpaidInvoice) &&
                errorDisplay(
                  'Please settle your outstanding invoices before requesting account cancellation.',
                )}
              {errorMessage && errorDisplay(errorMessage)}
            </>
          )}
        </div>
        {isCancelSubscriptionModalOpen && (
          <SubscriptionModal
            setIsOpen={setIsCancelSubscriptionModalOpen}
            title="Are you sure you want to cancel subscription?"
            message={`By choosing to cancel subscription, all activity and usage will stop, Please note you'll have to ${'\n'}clear any remaining balance to cancel the billing account.`}
            buttonLabel="Cancel Subscription"
            buttonColorClass={ButtonColor.Red}
            onButtonClick={onCancelSubscriptionClick}
          />
        )}

        {isResubscribeModalOpen && (
          <SubscriptionModal
            setIsOpen={setIsResubscribeModalOpen}
            title="You are about to Re-subscribe"
            message="You will be charged for the subscription from the primary card that you added."
            buttonLabel="Re-subscribe"
            buttonColorClass={ButtonColor.DARK_BLUE_2}
            onButtonClick={onResubscribeClick}
          />
        )}
      </div>
      <ToastContainer
        className="!w-max"
        position="bottom-center"
        hideProgressBar={true}
        autoClose={8000}
        closeOnClick
        pauseOnHover
      />
    </div>
  );
};
export default Subscription;
