import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ManageCustomersIcon } from '../../Assets/Images/svg/pageManageCustomers.svg';
import ViewCustomerIcon from '../../Assets/Images/svg/viewCustomerIcon.svg';
import DataTable from '../../Components/Common/DataTable';
import NavigationBar from '../../Components/Common/NavigationBar';
import Spinner from '../../Components/Common/Spinner';
import TitleCard from '../../Components/Common/TitleCard';
import { updateCurrentCustomer } from '../../Reducers/adminReducer';
import { AppDispatch, RootState } from '../../Store';
import { CustomerProps } from '../../Types';
import { fetchCustomersAsync } from '../../Utils/api';
import {
  DashBoardTab,
  INDIVIDUAL,
  MANAGE_CUSTOMER_TABLE_HEADERS,
  Navigation,
} from '../../Utils/constants';
import { formatDateString, makeFirstLetterCapital, toCamelCase } from '../../Utils/helperFunctions';

const MangeCustomer = () => {
  const dispatch = useDispatch<AppDispatch>();
  const customersDetails = useSelector((state: RootState) => state.customers);
  const [isInitialState, setIsInitialState] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [customers, setCustomers] = useState<CustomerProps[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [sortField, setSortField] = useState('');
  const [dropdownValue, setDropdownValue] = useState('active');

  const navigate = useNavigate();

  useEffect(() => {
    const updatedCustomers = customersDetails?.customers.map((customer) => {
      const name =
        customer.users && customer.users.length
          ? `${makeFirstLetterCapital(customer.users[0].firstName)} ${makeFirstLetterCapital(
              customer.users[0].lastName,
            )}`
          : customer.name;
      return {
        name:
          customer?.accountType === INDIVIDUAL
            ? name
            : makeFirstLetterCapital(customer.businessName ?? ''),
        domain: customer?.domain || '',
        account_type: makeFirstLetterCapital(customer?.accountType),
        created_at: formatDateString(customer?.activationDate),
        status: makeFirstLetterCapital(customer?.status.replace('-', ' ')),
        action: customer.id,
      };
    });
    setCustomers(updatedCustomers);
  }, [customersDetails]);

  useEffect(() => {
    if (isInitialState) {
      dispatch(
        fetchCustomersAsync(
          0,
          50,
          setIsLoading,
          setErrorMessage,
          navigate,
          sortOrder,
          sortField,
          inputValue,
          dropdownValue,
        ),
      );
      setIsInitialState(false);
    }
  }, []);

  const onPreviousPageClick = () => {
    if (currentPage === 0) return;
    dispatch(
      fetchCustomersAsync(
        currentPage - 1,
        50,
        setIsLoading,
        setErrorMessage,
        navigate,
        sortOrder,
        sortField,
        inputValue,
        dropdownValue,
      ),
    );
    setCurrentPage(currentPage - 1);
  };
  const onNextPageClick = () => {
    if (currentPage === customersDetails.totalPages - 1) return;
    dispatch(
      fetchCustomersAsync(
        currentPage + 1,
        50,
        setIsLoading,
        setErrorMessage,
        navigate,
        sortOrder,
        sortField,
        inputValue,
        dropdownValue,
      ),
    );
    setCurrentPage(currentPage + 1);
  };

  const onPageNumberClick = (pageNumber: number) => {
    dispatch(
      fetchCustomersAsync(
        pageNumber,
        50,
        setIsLoading,
        setErrorMessage,
        navigate,
        sortOrder,
        sortField,
        inputValue,
        dropdownValue,
      ),
    );
    setCurrentPage(pageNumber);
  };

  const handleViewCustomer = (id: string | number) => {
    dispatch(updateCurrentCustomer(Number(id)));
    navigate(Navigation.DASHBOARD);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSort = (column: string, direction: 'asc' | 'desc') => {
    setErrorMessage('');
    setSortOrder(direction);
    setSortField(toCamelCase(column));
    dispatch(
      fetchCustomersAsync(
        0,
        50,
        setIsLoading,
        setErrorMessage,
        navigate,
        direction,
        toCamelCase(column),
        inputValue,
        dropdownValue,
      ),
    );
  };

  const handleSearch = () => {
    dispatch(
      fetchCustomersAsync(
        0,
        50,
        setIsLoading,
        setErrorMessage,
        navigate,
        sortOrder,
        sortField,
        inputValue.trim(),
        dropdownValue,
      ),
    );
  };

  const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDropdownValue(event.target.value);
    dispatch(
      fetchCustomersAsync(
        0,
        50,
        setIsLoading,
        setErrorMessage,
        navigate,
        sortOrder,
        sortField,
        inputValue.trim(),
        event.target.value,
      ),
    );
  };

  const onSearchReset = () => {
    setInputValue('');
    dispatch(
      fetchCustomersAsync(
        0,
        50,
        setIsLoading,
        setErrorMessage,
        navigate,
        sortOrder,
        sortField,
        '',
        dropdownValue,
      ),
    );
  };

  return (
    <div className="flex pb-[20px] px-[23px] ">
      <div>
        <NavigationBar tab={DashBoardTab.MANAGE_CUSTOMERS} />
      </div>
      <div className="flex flex-col ml-[33px] w-screen">
        <TitleCard
          title="Manage Customers"
          icon={ManageCustomersIcon}
          isSearchBar={true}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          onSearch={handleSearch}
          isFilter={true}
          dropdownValue={dropdownValue}
          onDropdownChange={handleDropdownChange}
          onSearchReset={onSearchReset}
        />
        {errorMessage && (
          <p className="text-[10px] text-center text-red-0 max-w-[300px]">{errorMessage}</p>
        )}
        {customers.length > 0 && (
          <>
            {isLoading ? (
              <Spinner adjustContainerHeight />
            ) : (
              <div className="h-[590px]">
                <DataTable
                  tableHeader={MANAGE_CUSTOMER_TABLE_HEADERS}
                  data={customers}
                  height="h-[628px]"
                  totalPages={customersDetails?.totalPages}
                  currentPage={customersDetails?.currentPage}
                  highlightTableColumnValue="status"
                  highlightCondition="Suspended"
                  removePaginationBorder={true}
                  onButtonClick={handleViewCustomer}
                  onLeftArrowClick={onPreviousPageClick}
                  onRightArrowClick={onNextPageClick}
                  onPageNumberClick={onPageNumberClick}
                  highlightedCellStyling="text-red-0"
                  alternateHighlightedCellStyling="text-gray-1"
                  onSort={handleSort}
                  sortField={sortField}
                  sortOrder={sortOrder}
                >
                  <img className="h-5 w-5" src={ViewCustomerIcon} alt="Info Icon" />
                </DataTable>
              </div>
            )}
          </>
        )}
        {customers.length === 0 && (
          <p className="text-[14px] text-center text-red-0 w-full">No data available</p>
        )}
      </div>
    </div>
  );
};
export default MangeCustomer;
