import profileIcon from '../../Assets/Images/svg/profileIcon.svg';
import Dropdown from './Dropdown';
import LogoutSection from './LogoutSection';

type EmailType = {
  email: string;
};

const DashboardLogout = ({ email }: EmailType) => {
  return (
    <Dropdown options={<LogoutSection />}>
      <div className="flex items-center gap-1">
        <img src={profileIcon} alt="profile icon" />
        <p className="text-xs md:text-sm font-bold text-gray-1 opacity-70">{email}</p>
      </div>
    </Dropdown>
  );
};
export default DashboardLogout;
