import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ArrowIcon from '../../Assets/Images/svg/arrowIcon.svg';
import consumptionIcon from '../../Assets/Images/svg/consumption-icon.svg';
import tickIcon from '../../Assets/Images/svg/desktopPopupIcon.svg';
import infoIcon from '../../Assets/Images/svg/infoIcon.svg';
import linkIcon from '../../Assets/Images/svg/link-icon.svg';
import migrateIcon from '../../Assets/Images/svg/migrateIcon.svg';
import CancelIcon from '../../Assets/Images/svg/newCrossIcon.svg';
import InvoiceIcon from '../../Assets/Images/svg/newLastInvoiceIcon.svg';
import ChargeIcon from '../../Assets/Images/svg/newNextChargeIcon.svg';
import PaymentIcon from '../../Assets/Images/svg/newPaymentOptionIcon.svg';
import ThresholdIcon from '../../Assets/Images/svg/newThresholdAmountIcon.svg';
import BillIcon from '../../Assets/Images/svg/newUnbilledAmountIcon.svg';
import { ReactComponent as DashboardIcon } from '../../Assets/Images/svg/pageOverviewIcon.svg';
import supportIcon from '../../Assets/Images/svg/support-icon.svg';
import CustomButton from '../../Components/Common/CustomButton';
import NavigationBar from '../../Components/Common/NavigationBar/index';
import Spinner from '../../Components/Common/Spinner';
import TitleCard from '../../Components/Common/TitleCard';
import ToastMessage from '../../Components/Common/ToastMessage';
import Tooltip from '../../Components/Common/ToolTip';
import { AppDispatch, RootState } from '../../Store';
import {
  fetchCurrentUserAsync,
  fetchPaymentMethodData,
  getCustomerPackageDetailsAsync,
  getDashboardStatsAsync,
  retryPaymentAsync,
  updateDesktopRecommendedPopupAsync,
} from '../../Utils/api';
import {
  BILLING_STATUS,
  ButtonColor,
  DashBoardTab,
  Role,
  Status,
  StatusReason,
  SubscriptionStatus,
  ToastStatus,
} from '../../Utils/constants';
import { formatDateString, makeFirstLetterCapital } from '../../Utils/helperFunctions';
import { updatePaymentDisabled } from '../ManagePayment';
import MigrateProjectModal from './CustomerMigration/MigrateProjectModal';
import MigrateSuccessModal from './CustomerMigration/MigrationSuccessModal';
import DashboardCard from './DashboardCard';
import InformationSummaryCard from './InformationSummary/InformationSummaryCard';
import PaymentFailModal from './Modals/PaymentFailModal';
import PaymentSuccessModal from './Modals/PaymentSuccessModal';

const Dashboard = () => {
  const dispatch = useDispatch<AppDispatch>();

  const unBilledAmount = useSelector((state: RootState) => state.dashboard?.unbilledAmount);
  const lastInvoice = useSelector((state: RootState) => state.dashboard?.lastInvoice);
  const nextCharge = useSelector((state: RootState) => state.dashboard?.nextCharge);
  const currency = useSelector((state: RootState) => state.dashboard?.lastInvoice?.currency) || '';
  const {
    threshold,
    desktopVersion,
    oldGcpSubBillingAccount: oldGcpAccountId,
    gcpSubBillingAccountId,
    status,
    statusDetails,
  } = useSelector((state: RootState) => state.signup?.customer?.customer);
  const role = useSelector((state: RootState) => state.admin?.adminUser?.customer?.user?.role);
  const customerId = useSelector((state: RootState) => state.admin?.currentCustomer);
  const userCards = useSelector((state: RootState) => state.cards?.cards);
  const { URL_CNTXT_SUPPORT } = useSelector((state: RootState) => state.urls);
  const suspendedReason = statusDetails ? statusDetails.reason : null;
  const updateDisabled = updatePaymentDisabled(status, statusDetails);

  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogLoading, setIsDialogLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentSuccessModal, setPaymentSuccessModal] = useState(false);
  const [paymentFailModal, setPaymentFailModal] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(desktopVersion ?? true);
  const [isScreenWidthSmall, setIsScreenWidthSmall] = useState(window.innerWidth < 500);

  useEffect(() => {
    const handleResize = () => {
      setIsScreenWidthSmall(window.innerWidth < 500);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navigate = useNavigate();

  const onUpdateClick = () => {
    navigate('/managePayment');
  };

  const refreshStats = () => {
    if (role === Role.ADMIN) {
      dispatch(getDashboardStatsAsync(setIsLoading, setErrorMessage, navigate, true, customerId));
      dispatch(fetchCurrentUserAsync(setIsLoading, setErrorMessage, navigate, true, customerId));
      dispatch(fetchPaymentMethodData(setIsLoading, setErrorMessage, navigate, true, customerId));
      dispatch(
        getCustomerPackageDetailsAsync(setIsLoading, setErrorMessage, navigate, true, customerId),
      );
    } else {
      dispatch(getDashboardStatsAsync(setIsLoading, setErrorMessage, navigate, false));
      dispatch(fetchCurrentUserAsync(setIsLoading, setErrorMessage, navigate, true, customerId));
      dispatch(fetchPaymentMethodData(setIsLoading, setErrorMessage, navigate, false));
      dispatch(getCustomerPackageDetailsAsync(setIsLoading, setErrorMessage, navigate, false));
    }
  };
  useEffect(() => {
    refreshStats();
  }, []);

  const getPaymentOption = () => {
    if (userCards)
      return makeFirstLetterCapital(`${userCards[0]?.type} ****-${userCards[0]?.last4}`);
    else return '';
  };

  const getNextChargeValue = () => {
    if (nextCharge) {
      return formatDateString(nextCharge);
    } else if (lastInvoice?.issuedAt && lastInvoice?.status !== 'paid') {
      const issuedAt = moment(lastInvoice?.issuedAt);

      if (issuedAt.date() < 6) {
        return issuedAt.date(6).format('MMM DD, YYYY');
      } else {
        return moment(lastInvoice?.issuedAt)
          .add(1, 'months')
          .date(6)
          .format('MMM DD, YYYY');
      }
    } else if (lastInvoice?.issuedAt && lastInvoice?.status === 'paid') {
      const currentDate = moment();
      return currentDate.add(1, 'months').date(6).format('MMM DD, YYYY');
    } else {
      const currentDate = moment();
      if (currentDate.date() < 6) {
        return currentDate.date(6).format('MMM DD, YYYY');
      } else {
        return currentDate.add(2, 'months').date(6).format('MMM DD, YYYY');
      }
    }
  };

  function getTooltipTitle(): string {
    const status = lastInvoice?.status;
    switch (status) {
      case BILLING_STATUS.PENDING:
        return 'Unpaid';
      case BILLING_STATUS.FAILED:
        return 'Failed';
      case BILLING_STATUS.PAID:
        return 'Paid';
      default:
        return 'Not Available';
    }
  }

  function getTooltipText(): string {
    const status = lastInvoice?.status;
    switch (status) {
      case BILLING_STATUS.PENDING:
        return `Will be automatically charged on ${getNextChargeValue()}`;
      case BILLING_STATUS.PAID:
        return `Charged on ${formatDateString(lastInvoice.chargedAt)}`;
      case BILLING_STATUS.FAILED:
        return `Last attempt was on ${formatDateString(lastInvoice.last_bill_date)}`;
      default:
        return 'Not Available';
    }
  }

  const getUnbilledAmountValue = (unBilledAmount: number | null, currency: string) => {
    if (unBilledAmount === null) {
      return 'Not Available';
    }
    if (unBilledAmount === 0) {
      return '0';
    }
    return `${currency} ${parseFloat(Number(unBilledAmount)?.toFixed(2))?.toLocaleString()}`;
  };

  const handleMigrationModal = () => {
    setIsModalOpen(true);
  };

  const closeMobileDialog = () => {
    dispatch(
      updateDesktopRecommendedPopupAsync(checked, setIsDialogLoading, setErrorMessage, navigate),
    );
    setIsMobileModalOpen(false);
  };

  useEffect(() => {
    if (isMobileModalOpen && isScreenWidthSmall) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isMobileModalOpen, isScreenWidthSmall]);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleRetryPayment = () => {
    if (role === Role.ADMIN) {
      dispatch(
        retryPaymentAsync(
          setIsLoading,
          setErrorMessage,
          navigate,
          true,
          setPaymentSuccessModal,
          setPaymentFailModal,
          customerId,
        ),
      );
    } else {
      dispatch(
        retryPaymentAsync(
          setIsLoading,
          setErrorMessage,
          navigate,
          false,
          setPaymentSuccessModal,
          setPaymentFailModal,
        ),
      );
    }
  };
  const closePaymentSuccessDialog = () => {
    setPaymentSuccessModal(false);
    refreshStats();
  };

  const closePaymentFailDialog = () => {
    setPaymentFailModal(false);
    refreshStats();
  };

  return (
    <>
      {paymentFailModal && (
        <PaymentFailModal
          setIsModalOpen={setPaymentFailModal}
          closeDialog={closePaymentFailDialog}
        />
      )}
      {paymentSuccessModal && (
        <PaymentSuccessModal
          setIsModalOpen={setPaymentSuccessModal}
          closeDialog={closePaymentSuccessDialog}
        />
      )}
      <div className="block md:hidden">
        {isMobileModalOpen && (
          <div className="absolute z-30 bg-gray-1 bg-opacity-30 top-0 w-screen h-screen backdrop-blur-sm flex justify-center items-center">
            <div className="bg-white-0 z-30 rounded-[12px] max-w-[90%]">
              <div className="flex justify-end pr-4 pt-4">
                <button onClick={closeMobileDialog}>
                  <img
                    src={CancelIcon}
                    className="w-7 h-auto cursor-pointer"
                    alt="cancel upload icon"
                  />
                </button>
              </div>
              {isDialogLoading ? (
                <Spinner />
              ) : (
                <div className="py-12">
                  <div className="w-full flex justify-center">
                    <img className="" src={tickIcon} alt="success icon" />
                  </div>
                  <p className="text-[28px] text-center text-black-0 poppins-700">
                    Desktop Recommend
                  </p>
                  <p className="text-[18.3px] mx-auto text-center text-gray-7 max-w-[80%] md:max-w-[60%] poppins-400 mb-6">
                    Mobile version is coming soon. In the meantime, desktop is recommended for the
                    full experience.
                  </p>

                  <div className="flex justify-center">
                    <CustomButton
                      className="min-w-[80%] md:min-w-[300px]  font-bold"
                      colorClass={ButtonColor.DARK_BLUE}
                      onClick={closeMobileDialog}
                    >
                      OK
                    </CustomButton>
                  </div>
                  <div className="flex items-center justify-center mt-2">
                    <input
                      className="mr-2 custom-checkbox"
                      type="checkbox"
                      checked={checked}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="resetVatIdCheckbox" className="text-sm text-gray-7 poppins-400">
                      Don&apos;t show this message again
                    </label>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div
        className={`flex pb-[20px] px-[20px] ${
          isMobileModalOpen && isScreenWidthSmall ? 'no-scroll' : ''
        }`}
      >
        <div>
          <NavigationBar tab={DashBoardTab.DASHBOARD} />
        </div>

        <div className="flex flex-col ml-[33px] w-full">
          <TitleCard title="Overview" icon={DashboardIcon} />
          {isLoading && <Spinner adjustContainerHeight />}

          {!isLoading && status === SubscriptionStatus.SUSPENDED && (
            <>
              {(suspendedReason === StatusReason.FAILED_PAYMENT ||
                suspendedReason === StatusReason.FAILED_SETTLEMENT) && (
                <ToastMessage
                  text={
                    userCards?.length === 0
                      ? 'Please add a primary card to your account to retry payment.'
                      : 'Your account has been suspended due to failed payment.'
                  }
                  description="Please charge your credit card or add a different credit card."
                  status={ToastStatus.ERROR}
                  onButtonClick={userCards?.length === 0 ? onUpdateClick : handleRetryPayment}
                  buttonText={userCards?.length === 0 ? 'Add Primary Card' : 'Retry Payment'}
                  disabled={isLoading}
                />
              )}
              {suspendedReason === StatusReason.SECURITY && (
                <ToastMessage
                  text="Your account is currently suspended for security review."
                  description="You will be notified via email once your account is reactivated."
                  status={ToastStatus.WARNING}
                />
              )}
              {suspendedReason === StatusReason.BUDGET_OVERAGE && (
                <ToastMessage
                  text="Your monthly quota has been reached. Your account is temporarily suspended."
                  description="You may resume service on the 1st of the next month or contact CNTXT support for assistance."
                  status={ToastStatus.ERROR}
                />
              )}
              {suspendedReason === StatusReason.TERMS_VIOLATION && (
                <ToastMessage
                  text="Your account has been suspended due to violation of CNTXT policies."
                  description="If you think your account was disabled by mistake, you may contact CNTXT support for an appeal."
                  status={ToastStatus.ERROR}
                />
              )}
            </>
          )}
          {!isLoading &&
            (status === SubscriptionStatus.ACTIVE || status === SubscriptionStatus.SUSPENDED) &&
            suspendedReason === StatusReason.PENDING_REVIEW && (
              <ToastMessage
                text="Your account is currently under review."
                description="We will notify you via email once the review is complete. You may receive a verification call from +966 56-852-0814 or +966 56-852-0415."
                status={ToastStatus.INFO}
              />
            )}

          {!isLoading && <InformationSummaryCard />}
          {!isLoading && (
            <>
              <div className="grid gap-8 grid-cols-3">
                <DashboardCard
                  image={InvoiceIcon}
                  title="Last Invoice"
                  value={`${
                    lastInvoice?.grossAmount !== null && lastInvoice?.grossAmount !== undefined
                      ? currency
                      : ''
                  } ${
                    lastInvoice?.grossAmount !== null && lastInvoice?.grossAmount !== undefined
                      ? parseFloat(Number(lastInvoice?.grossAmount)?.toFixed(2))?.toLocaleString()
                      : 'Not Available'
                  }`}
                >
                  {lastInvoice?.grossAmount > 0 && (
                    <Tooltip text={getTooltipText()}>
                      <div
                        className={`w-[85px] h-[28px] ${
                          lastInvoice?.status == Status.PAID ? 'bg-green-0' : 'bg-red-0'
                        }  flex justify-center items-center rounded-[5px] gap-1 cursor-default`}
                      >
                        <img src={infoIcon} alt="info icon" />
                        <p className="poppins-300 text-sm font-medium text-white-0">
                          {getTooltipTitle()}
                        </p>
                      </div>
                    </Tooltip>
                  )}
                </DashboardCard>
                <DashboardCard
                  image={ChargeIcon}
                  title="Next Charge"
                  value={getNextChargeValue()}
                />
                <DashboardCard
                  image={BillIcon}
                  title="Unbilled Amount"
                  value={getUnbilledAmountValue(unBilledAmount, currency)}
                  valueStyling="text-red-1"
                />
                <DashboardCard
                  image={PaymentIcon}
                  title={`Payment Option (${userCards?.length})`}
                  value={userCards?.length > 0 ? getPaymentOption() : 'Not Available'}
                >
                  {!updateDisabled && (
                    <button className="flex flex-row items-center " onClick={onUpdateClick}>
                      <p className="text-base poppins-400 text-blue-1 mr-[5px]">Update</p>
                      <img src={ArrowIcon} alt="Arrow" />
                    </button>
                  )}
                </DashboardCard>
                <DashboardCard
                  image={ThresholdIcon}
                  title="Threshold Amount"
                  value={
                    threshold
                      ? `${currency || 'SAR'} ${Math.ceil(threshold)?.toLocaleString()}`
                      : 'Not Available'
                  }
                />
                <div className="bg-white-0 border rounded-[10px] border-gray-2  w-full max-h-[200px] xxl:w-full xxl:h-auto flex flex-col items-start px-[23px] py-[21px]">
                  <div
                    className="flex flex-col items-start justify-center gap-8 poppins-300"
                    style={{ height: '-webkit-fill-available' }}
                  >
                    <a
                      className="cursor-pointer flex gap-2 items-center justify-center"
                      href={`https://console.cloud.google.com/billing/${gcpSubBillingAccountId?.replace(
                        'billingAccounts/',
                        '',
                      )}/reports`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img className="h-6 w-8" src={consumptionIcon} alt="consumption icon" />
                      <h1 className="font-light text-[16px] text-blue-1 hover:opacity-80 text-left">
                        View Consumption Details
                      </h1>
                      <img className="h-4 w-4" src={linkIcon} alt="link icon" />
                    </a>
                    {!oldGcpAccountId && role !== Role.ADMIN ? (
                      <button
                        className="cursor-pointer flex gap-2 items-center justify-center"
                        onClick={handleMigrationModal}
                      >
                        <img className="h-8 w-8" src={migrateIcon} alt="support icon" />
                        <h2 className="font-light text-[16px] text-blue-1 hover:opacity-80 text-left">
                          Migrate Existing Billing Account
                        </h2>
                      </button>
                    ) : (
                      <div className="opacity-50 flex gap-2">
                        <img className="h-8 w-8" src={migrateIcon} alt="support icon" />
                        <h2 className="font-light text-[16px] text-blue-1 text-left">
                          Migrate Existing Billing Account
                        </h2>
                      </div>
                    )}

                    <a
                      className="cursor-pointer flex gap-2 items-center justify-center"
                      href={URL_CNTXT_SUPPORT}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img className="h-8 w-8" src={supportIcon} alt="support icon" />
                      <h2 className="font-light text-lg text-blue-1 hover:opacity-80 text-left">
                        Get Support
                      </h2>
                      <img className="h-4 w-4" src={linkIcon} alt="link icon" />
                    </a>
                  </div>
                </div>
              </div>
              {errorMessage && (
                <p className="text-[14px] text-left text-red-0 max-w-[300px]">{errorMessage}</p>
              )}
              {isModalOpen && (
                <MigrateProjectModal
                  setIsModalOpen={setIsModalOpen}
                  setIsSuccessModalOpen={setIsSuccessModalOpen}
                />
              )}
              {isSuccessModalOpen && <MigrateSuccessModal setIsModalOpen={setIsSuccessModalOpen} />}
            </>
          )}
        </div>
        <ToastContainer
          className="!w-max"
          position="bottom-center"
          hideProgressBar={true}
          autoClose={8000}
          closeOnClick
          pauseOnHover
        />
      </div>
    </>
  );
};
export default Dashboard;
