import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LogoSection from '../../../Components/Common/LogoSection';
import Spinner from '../../../Components/Common/Spinner';
import Stepper from '../../../Components/Common/Stepper';
import StepperMobile from '../../../Components/Common/StepperMobile';
import { updateOnboardingStep } from '../../../Reducers/signupReducer';
import { AppDispatch, RootState } from '../../../Store';
import { createBillingGroupAsync } from '../../../Utils/api';
import { BUSINESS, ONBOARDING_STEPS } from '../../../Utils/constants';
import { validateEmail } from '../../../Utils/helperFunctions';
import ButtonsSection from '../Common/ButtonsSection';

const BillingEmail = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const accountType = useSelector((state: RootState) => state.signup.accountType);
  const gcpSecurityGroup = useSelector(
    (state: RootState) => state.signup.customer?.customer?.gcpSecurityGroup,
  );
  const email = useSelector((state: RootState) => state.signup.customer?.customer?.user.email);
  const { URL_INSTRUCTIONS_GROUP } = useSelector((state: RootState) => state.urls);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [billingGroup, setBillingGroup] = useState(gcpSecurityGroup ?? email);
  const [emailError, setEmailError] = useState('');

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setBillingGroup(email);

    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };

  const handleBackStep = () => {
    if (accountType === BUSINESS) {
      dispatch(updateOnboardingStep(ONBOARDING_STEPS.UPLOAD_DOCUMENTS));
    } else {
      dispatch(updateOnboardingStep(ONBOARDING_STEPS.CONTACT_INFORMATION));
    }
  };

  const handleNextStep = () => {
    if (!emailError) {
      setErrorMessage('');
      dispatch(createBillingGroupAsync(billingGroup, setIsLoading, setErrorMessage, navigate));
    }
  };
  return (
    <div className="w-full h-full flex items-stretch lg:items-center justify-center">
      {isLoading && <Spinner />}
      {!isLoading && (
        <div className="border-[1px] border-gray-3 rounded-lg py-4 px-8 bg-white-0 min-h-[680px] w-[98%] overflow-auto flex flex-col justify-start">
          <LogoSection />
          <Stepper type={BUSINESS} currentStep={4} />
          <StepperMobile
            stepNumber={4}
            stepName="Billing Email"
            nextStep="Payment Information"
            totalSteps={5}
          />
          <div className="flex md:flex-row flex-col gap-2 my-5 justify-between">
            <div className="flex flex-col gap-y-3 w-full md:w-1/2 h-full">
              <h1 className="text-2xl font-light text-left text-gray-16 poppins-700">
                Assign Google Cloud Billing Administrator Permission
              </h1>
              <div className="flex flex-col md:w-2/3 gap-12">
                <ul className="text-left text-xs font-light list-disc pl-4">
                  <li className="mb-4 text-gray-7 poppins-300">
                    <span className="font-semibold poppins-500 text-black-0">
                      Workspace/Cloud Identity Group:
                    </span>{' '}
                    Enter the name of an existing workspace/cloud identity group, or create a new
                    one using these{' '}
                    <span>
                      <a
                        className="text-blue-1 underline hover:opacity-80"
                        href={URL_INSTRUCTIONS_GROUP}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        instructions.
                      </a>
                    </span>{' '}
                    Members of this group will be granted Google Cloud Billing Administrator
                    permissions.
                  </li>
                  <li className="text-gray-7 poppins-300">
                    <span className="font-semibold poppins-500 text-black-0">Permissions:</span>{' '}
                    These permissions allow members to attach billing account to Google Cloud
                    projects.
                  </li>
                </ul>
                <div>
                  <h3 className="text-sm font-bold text-left leading-loose text-gray-1 poppins-500">
                    Billing group name
                  </h3>
                  <input
                    className="border border-gray-2 p-2 w-full rounded-lg poppins-500 py-4"
                    type="text"
                    placeholder="billing_group@company.com"
                    value={billingGroup}
                    onChange={handleEmailChange}
                  />
                  {emailError && <p className="text-red-0 text-left">{emailError}</p>}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-auto">
            <ButtonsSection
              errorMessage={errorMessage}
              isBackButton={true}
              backFunction={handleBackStep}
              isNextButton={true}
              nextFunction={handleNextStep}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default BillingEmail;
