import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BillingHistoryIcon } from '../../../Assets/Images/svg/billingHistoryIcon.svg';
import CntxtLogo from '../../../Assets/Images/svg/cntxtLogo.svg';
import { ReactComponent as DarkBillingHistoryIcon } from '../../../Assets/Images/svg/darkBillingHistoryIcon.svg';
import { ReactComponent as DarkManageCustomersIcon } from '../../../Assets/Images/svg/darkManageCustomersIcon.svg';
import { ReactComponent as DarkUserIcon } from '../../../Assets/Images/svg/darkManageusersIcon.svg';
import { ReactComponent as DarkCardIcon } from '../../../Assets/Images/svg/DarkMyCards.svg';
import { ReactComponent as DashboardIconDark } from '../../../Assets/Images/svg/darkOverviewIcon.svg';
import { ReactComponent as DarkSubscriptionIcon } from '../../../Assets/Images/svg/darkSubscriptionIcon.svg';
import { ReactComponent as ManageCustomersIcon } from '../../../Assets/Images/svg/manageCustomersIcon.svg';
import { ReactComponent as UserIcon } from '../../../Assets/Images/svg/manageUsersIcon.svg';
import { ReactComponent as CardIcon } from '../../../Assets/Images/svg/myCards.svg';
import { ReactComponent as DashboardIcon } from '../../../Assets/Images/svg/overviewIcon.svg';
import { ReactComponent as SubscriptionIcon } from '../../../Assets/Images/svg/subscriptionIcon.svg';

import { RootState } from '../../../Store';
import { BUSINESS, DashBoardTab, Navigation, Role } from '../../../Utils/constants';
import NavigationButton from './NavigationButton';

type NavigationBarProps = {
  tab: number;
};

const NavigationBar = ({ tab }: NavigationBarProps) => {
  const navigate = useNavigate();
  const accountType = useSelector(
    (state: RootState) => state.signup?.customer?.customer?.accountType,
  );
  const role = useSelector((state: RootState) => state.admin?.adminUser?.customer?.user?.role);

  const dashboardTabs = [
    {
      id: DashBoardTab.DASHBOARD,
      title: 'Overview',
      icon: DashboardIcon,
      darkIcon: DashboardIconDark,
      navigation: Navigation.DASHBOARD,
    },
    {
      id: DashBoardTab.BILLING_HISTORY,
      title: 'Billing History',
      icon: BillingHistoryIcon,
      darkIcon: DarkBillingHistoryIcon,
      navigation: Navigation.BILLING_HISTORY,
    },

    {
      id: DashBoardTab.MY_CARDS,
      title: 'Manage Payment',
      icon: CardIcon,
      darkIcon: DarkCardIcon,
      navigation: Navigation.MY_CARDS,
    },
    {
      id: DashBoardTab.SUBSCRIPTION,
      title: 'Subscription',
      icon: SubscriptionIcon,
      darkIcon: DarkSubscriptionIcon,
      navigation: Navigation.SUBSCRIPTION,
    },
  ];

  if (accountType === BUSINESS) {
    dashboardTabs.splice(2, 0, {
      id: DashBoardTab.MANAGE_USERS,
      title: 'Manage Users',
      icon: UserIcon,
      darkIcon: DarkUserIcon,
      navigation: Navigation.MANAGE_USERS,
    });
  }

  if (role === Role.ADMIN) {
    dashboardTabs.unshift({
      id: DashBoardTab.MANAGE_CUSTOMERS,
      title: 'Manage Customers',
      icon: ManageCustomersIcon,
      darkIcon: DarkManageCustomersIcon,
      navigation: Navigation.MANAGE_CUSTOMERS,
    });
  }

  return (
    <div
      className={`h-full min-h-[700px] w-[275px]  flex flex-col items-center bg-white-0 border rounded-[10px] border-gray-2 py-[30px]`}
    >
      <div className="flex flex-col">
        <img className="w-[184px] h-auto" src={CntxtLogo} alt="cntxt logo" />
        <div className="w-[220px] h-px bg-gray-2 mb-[40px] mt-[19px]" />
      </div>
      <div className={`grid gap-2`}>
        {dashboardTabs.map((tabObject) => (
          <NavigationButton
            key={tabObject.title}
            title={tabObject.title}
            icon={tabObject.icon}
            darkIcon={tabObject.darkIcon}
            active={tab === tabObject.id}
            onClick={() => navigate(tabObject.navigation)}
            disable={tab === DashBoardTab.MANAGE_CUSTOMERS}
          />
        ))}
      </div>
    </div>
  );
};

export default NavigationBar;
