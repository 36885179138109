import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import NafathLogo from '../../../../Assets/Images/svg/NafathLogo.svg';
import Modal from '../../../../Components/Common/Modal';
import { identificationType } from '../../../../Utils/helperFunctions';

type ModalTypes = {
  identificationNumber: string;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  totalSeconds: number;
  random: string;
  onCancelRequest: () => void;
};

const NafathVerification = ({
  identificationNumber,
  setIsModalOpen,
  totalSeconds,
  random,
  onCancelRequest,
}: ModalTypes) => {
  const formatTime = () => {
    return totalSeconds > 1 ? `${totalSeconds} seconds` : `${totalSeconds} second`;
  };

  return (
    <Modal title="" setIsModalOpen={setIsModalOpen} onModalClose={onCancelRequest}>
      <div className="w-full flex items-center justify-center">
        <img className="mt-6" src={NafathLogo} alt="Nafath logo" />
      </div>
      <h3 className="poppins-700 text-[24px] text-center mt-4">Identity Verification</h3>
      <p className="poppins-400 text-gray-7 opacity-70 max-w-[786px] my-4 text-center mx-auto">
        We need to verify your identity (for {identificationType(identificationNumber)}:{' '}
        <strong>{identificationNumber}</strong>). Please open <strong>Nafath app</strong>, approve
        the authentication request received from CNTXT and select the below number on your mobile.
      </p>
      <div className="w-full flex items-center justify-center mt-6">
        <div className="w-[90px] h-[90px] relative">
          <CircularProgressbar
            value={totalSeconds}
            minValue={0}
            maxValue={180}
            strokeWidth={4}
            styles={buildStyles({
              textSize: '24px',
              pathTransitionDuration: 2,
              pathColor: `#2828F0`,
              textColor: '#000000',
              trailColor: '#DBDBDB',
            })}
          />
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: '24px',
              color: '#000',
              fontWeight: 'bold',
            }}
          >
            {random}
          </div>
        </div>
      </div>

      <p className="poppins-400 text-gray-7 opacity-70 max-w-[786px] mt-4 mb-10 text-center">
        The code will expire in: {formatTime()}
      </p>
    </Modal>
  );
};

export default NafathVerification;
