import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BlocksImage from '../../Assets/Images/svg/BlocksImg.svg';
import CntxtLogo from '../../Assets/Images/svg/cntxtLogo.svg';
import GoogleLogo from '../../Assets/Images/svg/googleLogo.svg';
import InstagramLogo from '../../Assets/Images/svg/instagramLogo.svg';
import LandingImage from '../../Assets/Images/svg/LandingImg.svg';
import LinkedinLogo from '../../Assets/Images/svg/linkedinLogo.svg';
import LeftPattern from '../../Assets/Images/svg/patternLeft.svg';
import RightPattern from '../../Assets/Images/svg/patternRight.svg';
import XLogo from '../../Assets/Images/svg/xLogo.svg';

import Spinner from '../../Components/Common/Spinner';
import { signInWithGooglePopup } from '../../Firebase/index';
import { updateAccountType, updateUserToken } from '../../Reducers/signupReducer';
import { AppDispatch } from '../../Store';
import { fetchCustomerDetailsAsync, fetchWebURLsAsync } from '../../Utils/api';
import { BUSINESS, INDIVIDUAL } from '../../Utils/constants';

const Login = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async () => {
    try {
      const response = await signInWithGooglePopup();
      console.log(response);
      const userEmail = response.user.email;
      response.user.getIdToken().then((result) => {
        dispatch(updateUserToken(result));
        if (userEmail?.includes('gmail')) {
          dispatch(updateAccountType(INDIVIDUAL));
          dispatch(fetchCustomerDetailsAsync(INDIVIDUAL, navigate, setIsLoading, setErrorMessage));
        } else {
          dispatch(updateAccountType(BUSINESS));
          dispatch(fetchCustomerDetailsAsync(BUSINESS, navigate, setIsLoading, setErrorMessage));
        }
        dispatch(fetchWebURLsAsync(setIsLoading, setErrorMessage, navigate));
      });
    } catch (error) {
      //Remove console log later
      console.error('Login failed:', error);
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="absolute top-0 left-0 bg-custom-gradient-black w-screen min-h-screen ">
          <img className="block absolute top-0 left-0 h-screen" src={LeftPattern} alt="" />
          <img
            className="hidden md:block absolute top-0 right-0 h-screen"
            src={RightPattern}
            alt=""
          />

          <div className="flex flex-col min-h-screen justify-between">
            <div className="w-full h-full xl:h-[calc(100vh-200px)] px-[8%] flex items-center justify-center gap-4">
              <div className="w-[100%] md:w-[50%] md:px-8 px-2">
                <div className="flex flex-col items-start text-[16px] md:text-[22px] text-left mt-16">
                  <p className="font-fieldwork text-white-0 text-[20px] md:text-[28px]">
                    Welcome to
                  </p>
                  <div className="flex items-start gap-4">
                    <img
                      className="w-[180px] md:w-[220px] h-auto"
                      src={CntxtLogo}
                      alt="cntxt logo"
                    />
                    <p className="font-fieldwork text-[40px] md:text-[58px] leading-120 text-white-0 font-[200]">
                      {' '}
                      Portal
                    </p>
                  </div>
                  <p className="font-fieldwork text-[40px] md:text-[58px] text-white-0 leading-120 md:leading-110 mb-4">
                    {' '}
                    for <span className="font-fieldwork font-[700]"> Google Cloud</span>
                  </p>
                  <div className="md:hidden w-[100%] flex flex-col justify-end my-4">
                    <img className="w-full" src={LandingImage} alt="" />
                  </div>
                  <p className="text-[16px] md:text-[18px] font-poppins text-gray-14">
                    Unlock the power of Google Cloud with the CNTXT Portal, offering instant access
                    to the Google Cloud Dammam region. Designed for both public and private
                    customers, our portal lets you seamlessly utilize Google Cloud services and pay
                    conveniently by credit card.
                  </p>
                  <p className="gradient-text font-semibold font-poppins capitalize">
                    START YOUR CLOUD JOURNEY NOW!
                  </p>
                  <div className="flex justify-center w-full py-6 z-30">
                    <button
                      className="flex justify-center items-center border border-gray-14 bg-black-0 rounded-md px-8 gap-4 py-4 min-w-full"
                      onClick={handleLogin}
                    >
                      <img className="w-[28px] h-[28px]" src={GoogleLogo} alt="" />
                      <p className="font-poppins text-sm md:text-lg poppins-500 text-gray-19 whitespace-nowrap">
                        Continue with Google
                      </p>
                    </button>
                  </div>

                  {errorMessage && (
                    <p className="text-[16px] py-2 text-center text-red-0 max-w-[300px] md:max-w-[80%]">
                      {errorMessage}
                    </p>
                  )}
                  <p className="text-gray-14 md:text-[14px] text-[12px]">
                    <strong className="text-white-0">Business</strong> Please log in with your
                    Google Workspace email.
                  </p>
                  <p className="text-gray-14 md:text-[14px] text-[12px] z-30">
                    {' '}
                    Not a Google Workspace user?{' '}
                    <a
                      className="underline"
                      href="https://support.google.com/cloudidentity/topic/7555414"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Create a free Google Cloud Identity account
                    </a>
                  </p>
                  <p className="text-gray-14 pt-2 md:text-[14px] text-[12px]">
                    <strong className="text-white-0">Non-Business</strong> Use your Gmail account.
                  </p>
                </div>
              </div>
              <div className="hidden h-full mt-[200px] w-[50%] md:flex flex-col items-center justify-center">
                <img className="flex flex-col" src={LandingImage} alt="" />
              </div>
            </div>
            <div className="w-full flex items-end mt-12">
              <img
                className="w-[60px] md:w-[8%] max-w-[300px] xxl:w-[5%] mr-[0%] xxl:mr-[3%]"
                src={BlocksImage}
                alt=""
              />
              <div className="w-full flex-col md:flex-row items-end flex px-[32px] pb-4  z-30">
                <div className="w-full">
                  <p className="text-gray-14 text-left poppins-500">Have questions?</p>
                  <p className="text-gray-14 text-left poppins-400">
                    Access our FAQ page{' '}
                    <a
                      className="underline"
                      href="https://content.cntxt.com/faq_portal_for_gcp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </a>
                  </p>
                </div>
                <div className="flex gap-4 h-full mt-4 md:mt-0">
                  <a href="https://x.com/cntxt_sa" target="_blank" rel="noopener noreferrer">
                    <img className="w-6 h-6" src={XLogo} alt="x logo" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/cntxt/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img className="w-6 h-6" src={LinkedinLogo} alt="linkedin logo" />
                  </a>
                  <a
                    href="https://www.instagram.com/cntxt_sa"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img className="w-6 h-6" src={InstagramLogo} alt="instagram logo" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Login;
