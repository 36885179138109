import React, { useState } from 'react';
import DropdownIcon from '../../Assets/Images/svg/dropdownIcon.svg';

type Props = {
  options: React.ReactNode;
  children?: React.ReactNode;
};

const Dropdown = ({ options, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <button
      className="relative flex justify-center items-center md:z-30"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <div className="flex justify-center items-center w-full hover:bg-gray-50 cursor-pointer">
        {children}
        <img
          src={DropdownIcon}
          alt="dropdown icon"
          className={`transform ${isOpen ? 'rotate-180' : ''} transition-transform duration-200`}
        />
      </div>

      {isOpen ? (
        <div className="absolute flex justify-end w-40 right-2 top-6">
          <div className="bg-white-0 p-2 shadow-3xl">{options}</div>
        </div>
      ) : null}
    </button>
  );
};

export default Dropdown;
