import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubscriptionProps } from '../Types';

const initialState: SubscriptionProps = {
  package: '',
  status: '',
  activationDate: '',
  cancellationDate: '',
  subscriptionDate: '',
  statusDetails: null,
  createdAt: '',
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    fetchSubscriptionDetails: (state, action: PayloadAction<SubscriptionProps>) => {
      state.package = action.payload.package;
      state.status = action.payload.status;
      state.activationDate = action.payload.activationDate;
      state.subscriptionDate = action.payload.subscriptionDate;
      state.cancellationDate = action.payload.cancellationDate;
      state.statusDetails = action.payload.statusDetails;
    },
    resetSubscriptionData: (state) => {
      state.package = initialState.package;
      state.status = initialState.status;
      state.activationDate = initialState.activationDate;
      state.cancellationDate = initialState.cancellationDate;
      state.subscriptionDate = initialState.subscriptionDate;
      state.statusDetails = initialState.statusDetails;
      state.createdAt = initialState.createdAt;
    },
  },
});

export const { fetchSubscriptionDetails, resetSubscriptionData } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
