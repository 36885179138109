import React from 'react';

interface SelectProps {
  options: string[];
  value: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  name: string;
  dropdownIcon: string;
  className?: string;
}

const SelectField: React.FC<SelectProps> = ({
  options,
  value,
  onChange,
  name,
  dropdownIcon,
  className,
}) => {
  return (
    <div className="relative flex flex-row">
      <select
        className={`${className} border appearance-none [&::-webkit-appearance]:hidden [&::-webkit-outer-spin-button]:hidden [&::-webkit-inner-spin-button]:hidden border-gray-2 w-full rounded-[4px] p-2 pr-10`}
        value={value}
        onChange={onChange}
        name={name}
      >
        {(!value || value === '') && <option value="">Select</option>}
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <div className="absolute pointer-events-none right-0 inset-y-0 items-center flex px-2 text-gray-700">
        <img alt="arrow" src={dropdownIcon} className="h-6 w-6" />
      </div>
    </div>
  );
};

export default SelectField;
