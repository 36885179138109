import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type UserProps = {
  firstName: string;
  lastName: string;
};

type CustomerProps = {
  name: string;
  businessName: string | null;
  domain: string | null;
  accountType: string;
  status: string;
  id: number;
  createdAt: string;
  activationDate: string;
  users?: UserProps[];
};

export interface CustomersState {
  totalItems: number;
  totalPages: number;
  currentPage: number;
  customers: CustomerProps[];
}

const defaultCustomer: CustomerProps = {
  status: '',
  id: 0,
  name: '',
  accountType: '',
  createdAt: '',
  activationDate: '',
  businessName: null,
  domain: null,
  users: [],
};

const initialState: CustomersState = {
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
  customers: [defaultCustomer] as CustomerProps[],
};

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    fetchCustomers: (state, action: PayloadAction<CustomersState>) => {
      state.totalItems = action.payload.totalItems;
      state.totalPages = action.payload.totalPages;
      state.currentPage = action.payload.currentPage;
      state.customers = action.payload.customers;
    },
    resetCustomers: (state) => {
      state.totalItems = initialState.totalItems;
      state.totalPages = initialState.totalPages;
      state.currentPage = initialState.currentPage;
      state.customers = initialState.customers;
    },
  },
});

export const { fetchCustomers, resetCustomers } = customersSlice.actions;

export default customersSlice.reducer;
