import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../Store';
import { Navigation, Role, TrackStep } from '../Utils/constants';
import PathsAllowed from './PathsAllowed';

interface PrivateRouteType {
  children: React.ReactElement;
  redirectTo?: string;
  pathLink: string;
}
interface PublicRouteType {
  children: React.ReactElement;
  redirectTo?: string;
}

export const PrivateRoute: React.FC<PrivateRouteType> = ({
  children,
  redirectTo = '/',
  pathLink,
}) => {
  const step = useSelector((state: RootState) => state.signup?.customer?.customer?.step);
  const adminRole = useSelector((state: RootState) => state.admin?.adminUser?.customer?.user?.role);
  const role = useSelector((state: RootState) => state.signup?.customer?.customer?.user?.role);
  if (role || adminRole) {
    if (adminRole === Role.ADMIN && PathsAllowed[adminRole].includes(pathLink)) {
      return children;
    } else if (adminRole === '' && role === Role.USER && PathsAllowed[role].includes(pathLink)) {
      return step === TrackStep.COMPLETED ? children : <Navigate to={redirectTo} />;
    }
  }
  return <Navigate to={redirectTo} />;
};

export const PublicRoute: React.FC<PublicRouteType> = ({ children, redirectTo = '/dashboard' }) => {
  const step = useSelector((state: RootState) => state.signup?.customer?.customer?.step);
  const adminRole = useSelector((state: RootState) => state.admin?.adminUser?.customer?.user?.role);
  const role = useSelector((state: RootState) => state.signup?.customer?.customer?.user?.role);
  if (adminRole === Role.ADMIN) {
    return <Navigate to={Navigation.MANAGE_CUSTOMERS} />;
  } else if (adminRole === '' && role === Role.USER) {
    return step === TrackStep.COMPLETED ? <Navigate to={redirectTo} /> : children;
  }
  return children;
};
