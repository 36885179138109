import CustomButton from '../../Components/Common/CustomButton';
import Modal from '../../Components/Common/Modal';
import { ButtonColor } from '../../Utils/constants';

type SubscriptionModalProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  message: string;
  buttonLabel: string;
  buttonColorClass: ButtonColor;
  onButtonClick: () => void;
};

const SubscriptionModal = ({
  setIsOpen,
  title,
  message,
  buttonLabel,
  buttonColorClass,
  onButtonClick,
}: SubscriptionModalProps) => {
  return (
    <Modal title={title} setIsModalOpen={setIsOpen}>
      <div className="flex flex-col gap-3">
        <div>
          <p className="text-xs text-left text-gray-1 whitespace-pre-line">{message}</p>
        </div>
        <div className="flex justify-end gap-x-2">
          <CustomButton
            className="w-2/5 font-bold"
            onClick={onButtonClick}
            colorClass={buttonColorClass}
          >
            {buttonLabel}
          </CustomButton>
          <CustomButton
            className="min-w-[120px] font-bold"
            onClick={() => setIsOpen(false)}
            colorClass={ButtonColor.White}
          >
            Back
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
};

export default SubscriptionModal;
