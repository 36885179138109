import cardLogos from '../../../Assets/Images/svg/cardLogos.svg';

const CardOptions = () => {
  return (
    <div className="max-w-[550px] text-[22px] text-gray-1 text-opacity-70 text-left mr-8">
      <p className="text-gray-16 poppins-500">Accepted Payment Options</p>
      <img className="mb-8" src={cardLogos} alt="card logos" />
      <ul style={{ listStyleType: 'disc' }} className="pl-5 text-[18px] text-gray-7 poppins-300">
        <li>
          <p className="">
            This payment detail will be used to automatically charge your monthly invoices
          </p>
        </li>
        <li>
          <p>
            While adding credit card, SAR 1 and SAR 10 will be withheld for verification and then
            refunded back
          </p>
        </li>
        <li>
          <p>You will also be able to maintain your credit cards later from the dashboard</p>
        </li>
      </ul>
    </div>
  );
};

export default CardOptions;
