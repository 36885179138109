import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// We have just added dumy code for first reducer here which will be later on replaced with code of our first reducer. Once we start using redux in this project

export interface CounterState {
  value: number;
  incrementAmount: number;
}

const initialState: CounterState = {
  value: 0,
  incrementAmount: 1,
};

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += state.incrementAmount;
    },
    decrement: (state) => {
      state.value -= state.incrementAmount;
    },
    changeIncrementAmount: (state, action: PayloadAction<number>) => {
      state.incrementAmount = action.payload;
    },
  },
});

export const { increment, decrement, changeIncrementAmount } = counterSlice.actions;

export default counterSlice.reducer;
